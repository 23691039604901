// ConfirmModal.js
import React from 'react';
import {Modal} from "@mui/material";
import Button from "@mui/material/Button";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(
    (theme) => {
        return ({
            confirmButton: {
                marginRight: "10px !important"
            },
            cancelButton: {}
        })
    })
const ConfirmModal = ({open, handleClose, handleConfirm, submitAction}) => {

    const classes = useStyles()

    return <Modal open={open} onClose={handleClose}>
        <div style={{
            padding: 20,
            backgroundColor: 'white',
            margin: '20% auto',
            width: '300px',
            textAlign: 'center'
        }}>
            <h2>{submitAction?.confirm?.title || submitAction?.name || 'Submit Form'}</h2>
            <p>{submitAction?.confirm?.message || "Are you sure you want to submit the form?"}</p>
            <Button variant="contained"
                    color="primary"
                    className={classes.confirmButton}
                    onClick={handleConfirm}>{submitAction?.confirm?.options?.confirm || 'Confirm'}</Button>
            <Button variant="contained"
                    color="secondary"
                    className={classes.cancelButton}
                    onClick={handleClose}>{submitAction?.confirm?.options?.cancel || 'Cancel'}</Button>
        </div>
    </Modal>;
};

export default ConfirmModal;