import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import {Box, Tabs} from "@mui/material";
import Tab from "@mui/material/Tab";
import {mapLayout} from "./FreeformUtils";
import jsonpath from "jsonpath";
import {FreeformToolContext} from "../../JsonTool";
import {
    lightGray,
    tabBgColor,
    tabHoverBgColor,
    tabHoverTxColor,
    tabIndicatorColor,
    tabSelectedBgColor,
    tabSelectedHoverBgColor,
    tabSelectedHoverTxColor,
    tabSelectedTxColor,
    tabTxColor,
    whiteColor
} from "../../styles/getMuiTheme";
import cloneDeep from "lodash.clonedeep";
import getColumnLabelString from "../../util/TextUtils";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    );
}

const styles = {
    '& .MuiTab-root'                               : {
        backgroundColor: tabBgColor.hexa(),
        color          : tabTxColor.hexa(),
        textTransform  : "none",
        whiteSpace     : "pre",
        maxWidth       : "none",
        minHeight      : "42px",
        // boxShadow      : "inset 0 0 5px gray",
        paddingTop   : "5px !important",
        paddingBottom: "5px !important",
        margin         : "6px 0 0 10px",
        // borderRight    : "1px solid " + lightGray.hex(),
        // borderTop      : "1px solid " + lightGray.hex(),
        // borderLeft     : "1px solid " + lightGray.hex(),
        boxShadow       : "0 0 4px 2px" + lightGray.hex(),
        borderRadius    : "6px 6px 0 0",
        '&.Mui-selected': {
            color          : tabSelectedTxColor.hexa(),
            backgroundColor: tabSelectedBgColor.hexa(),
            '&:hover'      : {
                color          : tabSelectedHoverTxColor.hexa(),
                backgroundColor: tabSelectedHoverBgColor.hexa(),
            }
        },
        '&:hover'       : {
            color          : tabHoverTxColor.hexa(),
            backgroundColor: tabHoverBgColor.hexa(),
        },
    },
    '& .MuiTabs-flexContainer'                     : {
        backgroundColor: whiteColor.hexa(),
    },
    '& .MuiTabs-indicator'                         : {
        backgroundColor: tabIndicatorColor.hexa(),
    },
    '& .MuiTabs-scroller'                          : {
        paddingRight: "10px",
    },
    '& .MuiTabScrollButton-root'                   : {
        '&$disabled': {
            opacity: 0.2,
        },
    },
    '& .MuiTabScrollButton-horizontal.Mui-disabled': {
        width             : 0,
        transitionProperty: "width",
        transitionDuration: ".1s"
    }
}

export default function TabsLayout(props: { meta: any, data: any }) {
    const [data, datasources, detailID, idColumnName] = useContext(FreeformToolContext);

    const [selectedTabIndex, setSelectedTabIndex] = useState(0)

    const updateTabIndex = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTabIndex(newValue)
    }

    const tabs = [] as any

    props.meta.tabs.forEach((t: any) => {
        if (t.dataSource) {

            let dataForTabs = []
            let tableData   = data ? jsonpath.query(data, t.dataSource) : [];
            tableData.forEach((d: any) => {
                const newFields = cloneDeep(t.fields);
                newFields.forEach((f: any) => f.data = f.data.replaceAll("$.id", jsonpath.query(d, "$.id")[0]))
                const tabDisplayText = jsonpath.query(d, t.tabDisplayText)[0]?.toString() || '';
                tabs.push({
                    name  : tabDisplayText.length > 0 ? tabDisplayText : t.name,
                    fields: newFields
                })
            })

            //                 {
            //                   "name":"Tinetti Assessment",
            //                   "dataSource":"$.tinetti[*]",
            //                   "tabDisplayText":"$.id",
            //                   "fields":[
            //                     {
            //                       "type":"form-layout",
            //                       "data":"FormQuestionnaireView|ID=5|DETAILID=$.id"
            //                     }
            //                   ]
            //                 },
        } else {
            tabs.push(t)
        }
    })

    return <Box sx={{width: '100%'}}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <Tabs value={selectedTabIndex}
                  sx={styles}
                  variant={"scrollable"}
                  scrollButtons={"auto"}
                  onChange={updateTabIndex}
                  aria-label="basic tabs example">
                {
                    tabs.map((t: any) => {
                        const label = getColumnLabelString(t.name, 15)
                        return <Tab key={JSON.stringify(t)}
                                    sx={styles}
                                    id={t.name.replaceAll(" ", "_") + "_tab"}
                                    label={label}/>;
                    })
                }
            </Tabs>
        </Box>
        {tabs.map((t: any, index: number) => {
            const tabContents = t.fields?.map((f: any) => mapLayout(f, props.data ? props.data : null));
            return <TabPanel key={JSON.stringify(t) + index}
                             index={index}
                             value={selectedTabIndex}>{tabContents}</TabPanel>;
        })}
    </Box>
}
TabsLayout.propTypes = {
    meta: PropTypes.any.isRequired,
    data: PropTypes.any
}