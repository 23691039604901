import React from "react";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import {Textarea} from "@data-driven-forms/mui-component-mapper";
import {FormLabel} from "@mui/material";

const paragraphStyle = {
    marginTop   : 0,
    marginBottom: 4,
};

export default function TextAreaCustom(props: any) {
    const {
              autocompleter,
              longtext,
              label,
              isInTable,
              input,
              isRequired,
              meta: {error, touched, dirty}
          } = useFieldApi(props);

    if (autocompleter) {

        const subscribeMethod = (topic: string, msg: any) => {
            input.onChange(msg ? msg[autocompleter.field] : null)
        }

        PubSub.subscribe('autocompleter' + autocompleter.name, subscribeMethod);
    }
    const styles        = {margin: "22px 0 5px 0"};
    const isNotEditable = dirty === false && input.value && props.editable

    const lengthValidation = props.validations?.find((v: any) => v.type === "max-length")

    const maxLength = lengthValidation?.threshold || null

    return (props.isReadOnly || isNotEditable) ? <>
            <FormLabel>{label}</FormLabel>
            <div>{input.value}</div>
        </> :
        <>
            <Textarea id={input.name}
                      InputLabelProps={{shrink: false}}
                      inputProps={maxLength ? {maxLength: maxLength} : {}}
                      label={isInTable ? null : label}
                      disabled={isNotEditable}
                      placeholder={label}
                      sx={styles}
                      name={input.name}
                      error={error}
                      minRows={longtext?.minrows || 2}
                      value={input.value}
                      aria-describedby="my-helper-text"
                      required={isRequired}
                      onChange={input.onChange}/>
            {touched && error && <p style={paragraphStyle}>{error}</p>}
            {maxLength && <div> {maxLength - (input.value?.length || 0)} Characters remaining</div>}
        </>
}
