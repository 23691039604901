import {CUSTOMERS, MyDataContext} from "../../ToolsComponent";
import {MenuItem, Select, Theme} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import React, {useContext} from "react";
import {makeStyles} from "@mui/styles";
import {sidebarBgColor, sidebarTxColor} from "../../styles/getMuiTheme";

export function CustomerSelect(props: any) {

    const [state, setState] = useContext(MyDataContext);
    let selectMargin        = 10

    const useStyles = makeStyles(
        // @ts-ignore
        (theme: Theme) => {
            return ({
                customerSelectInput: {
                    width              : "calc(" + state.sideBarWidth + "px - " + (selectMargin * 2) + "px)",
                    color              : sidebarTxColor.hexa() + " !important",
                    backgroundColor    : sidebarBgColor.darken(0.2).hexa(),
                    border             : "0",
                    borderRadius       : "6px !important",
                    margin             : `5px ${selectMargin}px`,
                    height             : "44px",
                    fontSize           : "14px",
                    "& .MuiSelect-icon": {
                        color: sidebarTxColor.hexa()
                    }
                },
            });
        }
    );

    const classes = useStyles()

    if (!CUSTOMERS || CUSTOMERS.length <= 1) {
        return null;
    }

    function getCustomerMenuItem(c: any) {
        return <MenuItem
            id={"customer_select_option_" + c.name.replaceAll(" ", "_").replaceAll(/[,']/g, "")}
            key={c.name} value={c.name}>{c.name}
        </MenuItem>;
    }

    return props.isCollapsed ? <Tooltip
        title={"Customer"}
        placement="right"
        arrow={true}
        PopperProps={{modifiers: [{name: "offset", options: {offset: [0, 0],},},],}}>
        <Select
            id={"customer_select_dropdown"}
            className={classes.customerSelectInput}
            value={props.isCollapsed ? "" : state.currentCustomer?.name || ""}
            onChange={state.handleCustomerChange}>
            {CUSTOMERS?.map(getCustomerMenuItem)}
        </Select>
    </Tooltip> : <Select id={"customer_select_dropdown"}
                         className={classes.customerSelectInput}
                         value={state.currentCustomer?.name || ''}
                         onChange={state.handleCustomerChange}>
        {CUSTOMERS?.map(getCustomerMenuItem)}
    </Select>;
}

CustomerSelect.propTypes = {
    isCollapsed: PropTypes.bool.isRequired,
}