import React from "react";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import {StyledToolTip} from "../../../ColumnsUtil";
import {CustomTextField} from "./CustomTextField";
import {errorColor} from "../../../styles/getMuiTheme";

const paragraphStyle = {
    marginTop   : 0,
    marginBottom: 4,
    color: errorColor.hexa()
};

export default function TextFieldCustom(props: any) {
    const {
              autocompleter,
              label,
              description,
              isInTable,
              input,
              isRequired,
              meta,
              ...rest
          } = useFieldApi(props);

    if (autocompleter) {

        const subscribeMethod = (topic: string, msg: any) => {
            input.onChange(msg ? msg[autocompleter.field] : null)
        }

        PubSub.subscribe('autocompleter' + autocompleter.name, subscribeMethod);
    }

    const subscribeMethod = (topic: string, msg: any) => {
        input.onChange(msg)
    }

    PubSub.subscribe('self_' + input.name, subscribeMethod);

    let textFieldLabel: any;

    if (isInTable) {
        textFieldLabel = null;
    } else if (description) {
        textFieldLabel = <StyledToolTip key={input.name + "tooltip"} title={description}>
            <div>{label}</div>
        </StyledToolTip>;
    } else {
        textFieldLabel = label;
    }

    const isNotEditable = meta.dirty === false && input.value !== null && props.editable

    // return props.isReadOnly && input.value ? <div style={{marginTop: '15px', marginBottom: '-5px'}}>
    //     {textFieldLabel}: <span style={{fontWeight: "bold"}}>{input.value}</span>
    // </div> : <>
    return <>
        <CustomTextField
            id={input.name}
            label={textFieldLabel}
            error={Boolean(meta.error)}
            isReadOnly={props.isReadOnly || isNotEditable}
            disabled={props.isReadOnly || isNotEditable}
            aria-describedby="my-helper-text"
            required={isRequired}
            validating={meta.validating}
            {...input}
            {...rest}/>
        {meta.touched && meta.error && <p style={paragraphStyle}>{meta.error}</p>}</>;
}
