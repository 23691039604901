import jsonpath from "jsonpath";
import {useContext} from "react";
import {FreeformToolContext} from "../../JsonTool";
import {MyDataContext} from "../../ToolsComponent";
import FormComponent from "../forms/FormComponent";
import PropTypes from "prop-types";
import {ListLayout} from "./ListLayout";

export default function FormLayout(props: { meta: any, data: any }) {

    const [data, datasources, detailID, idColumnName] = useContext(FreeformToolContext);
    const [state]                                     = useContext(MyDataContext);

    let source = props.meta.data

    const detailIDorJsonPointer = source.split('|').find((s: string) => s.startsWith("DETAILID="))?.split("=")[1];
    const queryResults          = data ? jsonpath.query(data, detailIDorJsonPointer)[0] : null;
    const formDetailID          = queryResults || detailIDorJsonPointer

    return <FormComponent

        dbName={source}
        modal={false}
        idColumnName={'id'}
        customerObjectID={state.currentCustomer?.id}
        open={true}
        detailID={formDetailID}

    />
}

ListLayout.propTypes = {
    meta: PropTypes.any.isRequired,
    data: PropTypes.any
}