import {componentTypes} from "@data-driven-forms/react-form-renderer";
import {FORMS} from "../ToolsComponent";
import {componentMapper} from "@data-driven-forms/mui-component-mapper";
import SubFormCustom from "../components/forms/customMappers/SubFormCustom";
import FieldArrayCustom from "../components/forms/customMappers/FieldArrayCustom";
import TextFieldCustom from "../components/forms/customMappers/TextFieldCustom";
import TextAreaCustom from "../components/forms/customMappers/TextAreaCustom";
import SelectCustom from "../components/forms/customMappers/SelectCustom";
import DatePickerCustom from "../components/forms/customMappers/DatepickerCustom";
import RadioCustom from "../components/forms/customMappers/RadioCustom";
import ColorPickerFormComponent from "../components/forms/customMappers/ColorPickerFormComponent";
import IconFormComponent from "../components/forms/customMappers/IconFormComponent";
import AutocompleterCustom from "../components/forms/customMappers/AutocompleterCustom";
import DateTimePickerCustom from "../components/forms/customMappers/DateTimePickerCustom";
import DisplayField from "../components/forms/customMappers/DisplayField";
import CalculatedField from "../components/forms/customMappers/CalculatedField";
import React from "react";
import {Grid, Table, TableBody, TableCell, TableHead} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import getColumnLabelString from "./TextUtils";
import {blackColor, themeColor1} from "../styles/getMuiTheme";
import FileFieldCustom from "../components/forms/customMappers/FileFieldCustom";
import MatrixField from "../components/forms/customMappers/MatrixField";
import Expansion from "../components/forms/customMappers/Expansion";
import jsonpath from "jsonpath";
import {doPost} from "./RestUtil";


export function getForms(type: "HEADER" | "DRILLDOWN" | "GROUP_HEADER", name: any = null) {

    switch (type) {
        case 'HEADER':
            return FORMS.filter((form: any) => form.types.includes(type) && form.tool === name);
        case 'DRILLDOWN':
            return FORMS.filter((form: any) => form.types.includes(type) && form.tool === name);
        case 'GROUP_HEADER':
            return FORMS.filter((form: any) => form.types.includes(type) && form.group === name);
        default:
            throw Error("Unknown form type " + type)
    }

}

export const myComponentMapper = {
    ...componentMapper,
    [componentTypes.SUB_FORM]   : SubFormCustom,
    [componentTypes.FIELD_ARRAY]: FieldArrayCustom,
    [componentTypes.TEXT_FIELD] : TextFieldCustom,
    [componentTypes.TEXTAREA]   : TextAreaCustom,
    [componentTypes.SELECT]     : SelectCustom,
    [componentTypes.DATE_PICKER]: DatePickerCustom,
    [componentTypes.RADIO]      : RadioCustom,
    'color'                     : ColorPickerFormComponent,
    'icon'                      : IconFormComponent,
    'autocompleter': AutocompleterCustom,
    'datetime-picker'           : DateTimePickerCustom,
    'display'                   : DisplayField,
    'field-listener'            : CalculatedField,
    'file'                      : FileFieldCustom,
    'matrix'                    : MatrixField,
    'DEFAULT'      : TextFieldCustom

};

export function mapJsonToSchema(json: any, layout: any, customerObjectID: string | null, readOnly: boolean = false, detailID: any = null, formData: any, confirmFunc: any) {

    let schema = {fields: [] as any}

    json.fields.forEach((field: any) => {
        if (field.type === 'CHECKBOX' && field.checkbox?.stringValue === true) {
            let newField = mapField(field, layout, customerObjectID, readOnly, detailID, formData, confirmFunc);
            schema.fields.push(newField)
            schema.fields.push({
                component: 'field-listener',
                name     : newField.name + "_stringValue",
                hideField: true,
                formula  : {operation: "STRINGVALUE"}
            })
        }

        let newField = mapField(field, layout, customerObjectID, readOnly, detailID, formData, confirmFunc);
        schema.fields.push(newField)

        if (field.type === 'CALCULATED') {
            schema.fields.push({
                component: 'field-listener',
                name     : newField.name + '_listener',
                hideField: true,
                formula  : field.formula
            })
        }

        if (newField.mappings) {
            Object.values(newField.mappings).map((v: any) => {
                let field       = {
                    "formField": v.formField,
                    "type"     : 'HIDDEN'
                }
                let mappedField = mapField(field, layout, customerObjectID, readOnly, detailID, formData, confirmFunc)
                console.log(mappedField)
                schema.fields.push(mappedField)
            })
        }

    })

    return schema

}

const ConfirmationModal = ({isOpen, message, onConfirm, onCancel}: any) => {
    if (!isOpen) return null;

    return (
        <div className="modal">
            <div className="modal-content">
                <p>{message}</p>
                <button onClick={onConfirm}>Confirm</button>
                <button onClick={onCancel}>Cancel</button>
            </div>
        </div>
    );
};


function mapField(field: any, layout: any, customerObjectID: string | null, readOnly = false, detailID: any, formData: any, confirmFunc: any, arrayField: any = null) {
    let newField = {} as any

    newField['component'] = getComponentByType(field.type)
    if (field.initialValue === 'DETAIL_ID') {
        field.initialValue = detailID
    }
    if (field.initialValue?.startsWith("$")) {
        field.initialValue = jsonpath.query(formData, field.initialValue)[0]
    }
    if (field.type === 'HIDDEN') {
        newField['hideField'] = true
    }
    newField['name']               = field.formField
    newField['description']        = field.description
    newField['label']              = field.prefix ? field.prefix + ' ' + field.label : field.label
    newField['prefix']             = field.prefix
    newField['isRequired']         = field.validations?.filter((v: any) => v.type === 'REQUIRED').length > 0
    newField['isReadOnly']         = field.type === 'CALCULATED' ? true : field.readOnly !== null && field.readOnly !== undefined ? field.readOnly : readOnly
    newField['isDisabled']         = readOnly
    newField['options']            = field.options
    newField['orientation']        = field.orientation || 'VERTICAL'
    newField['FormFieldGridProps'] = field.width ? {xs: 12, sm: field.width} : {xs: 12, sm: 12, md: 12, lg: 12}
    newField['dbName']             = field.dbName
    newField['formFieldValue']     = field.formFieldValue // This is an optional name that would come from the view object populating an autocompleter field. No current implementations. Defaults to id in autocompleter
    newField['initialValue']       = field.options?.length === 1 ? field.options[0].value : null
    newField['customerObjectID']   = customerObjectID
    newField['layout']             = field.layout
    newField['validations']        = field.validations


    if (arrayField && arrayField.editable !== undefined && field.editable === undefined) {
        newField['editable'] = arrayField.editable
    } else {
        newField['editable'] = field.editable
    }


    // let layoutForField = findFieldAttr(field.formField, layout, "layout")
    //
    // if (layoutForField) {
    //     newField['layout'] = layoutForField
    // }

    if (field.autocompleter) {
        newField['autocompleter'] = field.autocompleter
        // input.onChange(input.value[formFieldValue || 'id'])

    }

    if (field.type === 'DEFAULT') {
        newField['initialValue'] = field.initialValue
    }

    if (field.checkbox) {
        newField['checkbox'] = field.checkbox
    }
    if (field.matrix) {
        newField['matrix'] = field.matrix
    }
    if (field.addButtonText) {
        newField['addButtonText'] = field.addButtonText
    }
    if (field.longtext) {
        newField['longtext'] = field.longtext
    }
    if (field.mappings) {
        newField['mappings'] = field.mappings
    }
    if (field.type === 'BOOLEAN') {
        newField['dataType'] = 'boolean'
    }

    field.validations = field.validations?.sort((v1: any, v2: any) => {

        if (v1.type === v2.type) {
            return 0
        }
        if (v2.type === 'REST_URL') {
            return 1
        }
        if (v1.type === 'REST_URL') {
            return -1
        }

    })

    newField['validate'] = field.validations?.map((validation: any) => {

        switch (validation.type) {
            case 'REQUIRED':
                return (value: any) => (value === null || value === undefined ? `Field '${newField['label']}' is required` : undefined)
            case 'REST_URL':
                // return (value: any) => (value === 'John' ? `John Sucks` : undefined)
                let timer: string | number | NodeJS.Timeout | undefined;
                return (value: any) => {
                    const promise = (v: any) => new Promise(async (resolve, reject) => {
                        if (value === null) {
                            resolve("No val")
                            return
                        }
                        clearTimeout(timer)
                        timer = setTimeout(async () => {

                            const url = validation.url.replaceAll("{DETAIL_ID}", detailID)

                            const response      = await doPost(url, {value: v}, null, customerObjectID) as any
                            const validationObj = response.data
                            switch (validationObj.valid) {
                                case 'VALID':
                                    resolve({message: "Success"})
                                    break
                                case 'INVALID':

                                    reject({message: validationObj.message})
                                    break
                                case 'USER_INPUT':
                                    const newVar = await confirmFunc(validationObj.message) ? undefined : "Invalid input";

                                    if (!newVar) {
                                        resolve("Success")
                                        break
                                    } else {
                                        reject({message: newVar})
                                        break
                                    }
                                default:
                                    reject({message: "Unknown valid value " + validationObj.valid})
                            }
                        }, 1000)
                    });
                    return promise(value).catch(({message}) => {
                        throw message;
                    }).then(console.log)
                }
            default:
                return validation
        }

    })


    if (field.type === 'ARRAY' && field.fields) {
        newField['fields'] = []
        field.fields.forEach((f: any) => {
            let nf = mapField(f, layout, customerObjectID, readOnly, detailID, formData, confirmFunc, field);
            newField.fields.push(nf)
            if (newField.mappings) {
                console.log(newField.mappings)
                newField.mappings.values().map((v: string) => {

                })
            }
        })
    }

    return newField;
}

function findFieldAttr(formField: any, jsonNode: any, returnAttr: any): any {

    if (typeof jsonNode === "string") {
        return null;
    }

    if (jsonNode.field && formField === jsonNode.field && jsonNode[returnAttr]) {
        return jsonNode[returnAttr];
    }

    if (Array.isArray(jsonNode)) {
        for (let i = 0; i < jsonNode.length; i += 1) {
            let currentChild = jsonNode[i];
            let result       = findFieldAttr(formField, currentChild, returnAttr);
            if (result !== null) {
                return result;
            }
        }
    } else {
        for (const value of Object.values(jsonNode)) {
            let result = findFieldAttr(formField, value, returnAttr);
            if (result !== null) {
                return result;
            }
        }
    }

    return null;
}


function getComponentByType(type: string) {
    switch (type) {
        case "DROPDOWN":
            return componentTypes.SELECT
        case "DATETIME":
            return 'datetime-picker'
        case "DATE":
            return componentTypes.DATE_PICKER
        case "LONGTEXT":
            return componentTypes.TEXTAREA
        case "AUTOCOMPLETER":
            return 'autocompleter'
        case "RADIOGROUP":
            return componentTypes.RADIO
        case "DISPLAY":
            return 'display'
        case "ARRAY":
            return componentTypes.FIELD_ARRAY
        case "BOOLEAN":
            return componentTypes.CHECKBOX
        case "CHECKBOX":
            return componentTypes.CHECKBOX
        case "FILE":
            return 'file'
        case "MATRIX":
            return 'matrix'
        default:
            return componentTypes.TEXT_FIELD
    }
}

function getFormField(formFields: any, fieldName: any) {
    return formFields.find((ff: any) => ff.props.name === fieldName || ff.props.name.endsWith("." + fieldName) || ff.props.label === fieldName);
}

export function mapLayoutItem(item: any, formFields: any, additionalArgs: any = {}) {
    if (typeof item === "string") {

        const strings  = item.split("|");
        const itemName = strings[0]
        const widthStr = strings.find((s: string) => s.startsWith("WIDTH="));
        const width = widthStr ? parseInt(widthStr.split("=")[1]) : null

        const find = getFormField(formFields, itemName);

        if (!width) {
            return find ? find : <pre>{JSON.stringify(item)}</pre>
        }

        return <Grid item xs={width}>
            {find ? find : <pre>{JSON.stringify(item)}</pre>}
        </Grid>

    }

    switch (item.type) {
        case 'ROW':
            return mapRow(item, formFields, additionalArgs);
        case 'FIELDSET':
            return mapFieldset(item, formFields, additionalArgs);
        case 'EXPANSION':
            return mapExpansion(item, formFields, additionalArgs);
        case 'SECTION':
            return mapSection(item, formFields, additionalArgs);
        case 'TABLE':
            return mapTable(item, formFields, additionalArgs);
        case 'LINE':
            return mapLine(item);
        default:
            const find = getFormField(formFields, item.field);
            return (item.field && find)
                ||
                <pre>{JSON.stringify(item)}</pre>
    }
}

function mapRow(lrow: any, formFields: any[], additionalArgs: any = {}) {
    return <Grid item xs={12} key={JSON.stringify(lrow)} container spacing={2}>
        {lrow.columns?.map((col: any) =>
            <Grid item xs={col.width || 12}>
                {mapLayoutItem(col, formFields, additionalArgs)}
            </Grid>)}
        {lrow.children?.map((col: any) => {

            if (typeof col === 'string') {
                const strings  = col.split("|");
                const itemName = strings[0]
                const widthStr = strings.find((s: string) => s.startsWith("WIDTH="));
                const width    = widthStr ? parseInt(widthStr.split("=")[1]) : 12

                return <Grid item xs={width}>
                    {mapLayoutItem(itemName, formFields, additionalArgs)}
                </Grid>;

            } else {
                return <Grid item xs={col.width || 12}>
                    {mapLayoutItem(col, formFields, additionalArgs)}
                </Grid>;
            }
        })}
    </Grid>
}


function mapFieldset(fieldset: any, formFields: any[], additionalArgs: any = {}) {

    let standardFieldsetStyles = {
        border: "2px solid " + themeColor1.fade(0.7).hexa(),
        // border         : "0",
        borderRadius: "8px",
        // backgroundColor: "rgb(236,236,236)",
        color       : blackColor.hexa(),
        marginBottom: "5px"
        // boxShadow      : "0 0 5px 0px inset #aaa"
    }

    let standardLegendStyles = {
        fontSize  : "18px",
        fontWeight: 500,
        padding   : "0 5px 0 5px",
        color     : themeColor1.hexa()
    }

    return <fieldset style={standardFieldsetStyles}>
        {fieldset.legend && <legend style={standardLegendStyles}>{fieldset.legend}</legend>}
        {fieldset.title && <legend style={standardLegendStyles}>{fieldset.title}</legend>}
        {fieldset.sublegend &&
            <div style={{marginBottom: "10px", fontStyle: "italic"}}>{fieldset.sublegend}</div>}
        {fieldset.items?.map((item: any) => mapLayoutItem(item, formFields, additionalArgs))}
        {fieldset.children?.map((item: any) => mapLayoutItem(item, formFields, additionalArgs))}
    </fieldset>
}

function mapExpansion(expansion: any, formFields: any[], additionalArgs: any = {}) {

    //               "border": "1px black solid",
    //               "margin": "5px",
    //               "padding": "10px",
    //               "headerBgColor": "#FF0",
    //               "bodyBgColor": "#F0F",
    //               "headerTextColor": "#00F",
    //               "bodyTextColor": "#0FF",

    let title = expansion.title

    expansion.headerStyles    = expansion.headerStyles ? JSON.parse(JSON.stringify(expansion.headerStyles).replaceAll("themeColor1", themeColor1.hex())) : null
    expansion.bodyStyles      = expansion.bodyStyles ? JSON.parse(JSON.stringify(expansion.bodyStyles).replaceAll("themeColor1", themeColor1.hex())) : null
    expansion.containerStyles = expansion.containerStyles ? JSON.parse(JSON.stringify(expansion.containerStyles).replaceAll("themeColor1", themeColor1.hex())) : null

    return <Expansion key={JSON.stringify(expansion)}
                      title={title}
                      sx={expansion.styles}
                      onDelete={additionalArgs.onDelete || null}
                      headerStyles={expansion.headerStyles || {}}
                      bodyStyles={expansion.bodyStyles || {}}
                      containerStyles={expansion.containerStyles || {}}
                      expansionOpened={expansion.initialExpanded === "ALL" || true}
                      description={expansion.description}>
        <div style={{padding: "5px"}}>
            <Grid container spacing={2}>
                {expansion.items?.map((item: any) => mapLayoutItem(item, formFields, additionalArgs))}
                {expansion.children?.map((item: any) => mapLayoutItem(item, formFields, additionalArgs))}
            </Grid>
        </div>
    </Expansion>
}

function mapSection(section: any, formFields: any[], additionalArgs: any = {}) {

    let standardLegendStyles = {
        fontSize  : "18px",
        fontWeight: 500,
        color     : themeColor1.hexa()
    }

    return <Grid xs={12} container item spacing={2}>
        <Grid item xs={12} style={standardLegendStyles}>
            {section.legend ? section.legend : section.title}
            <hr/>
        </Grid>
        {section.items?.map((item: any) => mapLayoutItem(item, formFields, additionalArgs))}
        {section.children?.map((item: any) => mapLayoutItem(item, formFields, additionalArgs))}
    </Grid>;

}

function mapTable(table: any, formFields: any[], additionalArgs: any = {}) {
    return <Table>
        <TableHead>
            {[table.items[0]].map((i: string) => {
                const value = getFormField(formFields, i);
                return value ? <TableRow>
                    <TableCell></TableCell>{value.props.options.map((opt: any) => {
                    return <TableCell style={{whiteSpace: "pre"}}>
                        {getColumnLabelString(opt.label, 15)}
                    </TableCell>
                })}</TableRow> : null
            })}
        </TableHead>
        <TableBody>
            {table.items.map((i: string) => {
                const value = getFormField(formFields, i);
                return value ? {...value, props: {...value.props, isInTable: true}} : null
            })}
        </TableBody>
    </Table>
}

function mapLine(lineInfo: any) {
    return <hr key={JSON.stringify(lineInfo)} style={lineInfo.style || {
        backgroundColor: "#aaa",
        borderWidth    : 0,
        height         : "2px",
        width          : "90%",
    }}/>
}

export function mapFormFieldsRecursively(obj: any) {
    for (let k in obj) {
        if (obj[k + "_stringValue"]) {
            obj[k] = obj[k + "_stringValue"]
            delete obj[k + "_stringValue"]
        } else if (typeof obj[k] == "object" && obj[k] !== null && Array.isArray(obj[k])) {
            // console.log(`${k} is array`)
            mapFormFieldsRecursively(obj[k]);
        } else if (typeof obj[k] == "object" && obj[k] !== null && !Array.isArray(obj[k])) {
            // console.log(`${k} is object`)
            mapFormFieldsRecursively(obj[k]);
        } else {
            if (obj[k + "_stringValue"]) {
                obj[k] = obj[k + "_stringValue"]
                delete obj[k + "_stringValue"]
            }
        }
        // do something...
    }
}
