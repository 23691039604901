import React, {useContext, useState} from 'react';
import Drawer from '@mui/material/Drawer';
import {makeStyles} from "@mui/styles";
import LogoComponent from "../icons/logoComponent";
import {MyDataContext, NAVIGATION} from "../../ToolsComponent";
import {sidebarBgColor} from "../../styles/getMuiTheme";
import {ToolCategory} from "../../classes";
import {SidebarCollapseButton} from "./SidebarCollapseButton";
import {SidebarCategory} from "./SidebarCategory";
import {CollapsedSidebarCategory} from "./CollapsedSidebarCategory";
import {CustomerSelect} from "./CustomerSelect";
import {CurrentUser} from "./CurrentUser";

export default function Sidebar(props: any) {

    const [state, setState] = useContext(MyDataContext);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const COLLAPSED_WIDTH   = 60;
    const EXPANDED_WIDTH    = 275;

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
        isCollapsed ? state.handleSidebarResize(EXPANDED_WIDTH) : state.handleSidebarResize(COLLAPSED_WIDTH);
    };

    const useStyles = makeStyles(
        // @ts-ignore
        (theme) => {
            return {
                drawer             : {
                    backgroundColor: sidebarBgColor.hexa() + " !important",
                    width          : state.sideBarWidth,
                },
                parent             : {
                    width     : state.sideBarWidth,
                    height    : "100vh",
                    display   : "flex",
                    transition: "width 0.5s ease-in-out",
                },
                bottomMatter       : {
                    position       : "fixed",
                    width          : state.sideBarWidth,
                    backgroundColor: sidebarBgColor.hexa(),
                    bottom         : 0,
                    paddingBottom  : "5px"
                },
                sidebarTop         : {
                    padding : "24px 12px 12px 12px",
                    height  : "calc(100% - 120px)",
                    overflow: "auto"
                },
                sidebarTopCollapsed: {
                    padding : "0px",
                    height  : "calc(100% - 120px)",
                    overflow: "auto",
                },

            };
        }
    );

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick = (
        event: React.MouseEvent<HTMLElement>,
        categoryName: string
    ) => {
        setAnchorEl(event.currentTarget);
        setExpanded(categoryName);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setExpanded(false);
    };

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const classes = useStyles() as any;

    return (
        <div className={classes.parent}>
            <Drawer variant="permanent" PaperProps={{className: classes.drawer}}>
                <div className={isCollapsed ? classes.sidebarTopCollapsed : classes.sidebarTop}
                     id={"sidebar_navigation_root"}>
                    <LogoComponent isCollapsed={isCollapsed}/>
                    <SidebarCollapseButton isCollapsed={isCollapsed}
                                           toggleSidebar={toggleSidebar}/>
                    {NAVIGATION ? NAVIGATION.map((category: ToolCategory) => {
                        return isCollapsed ?
                            <CollapsedSidebarCategory category={category}
                                                      expanded={expanded}
                                                      handleClick={handleClick}
                                                      sideBarWidth={state.sideBarWidth}
                                                      toolStacks={state.toolStacks}
                                                      handleSidebarToolClick={state.handleSidebarToolClick}
                                                      anchorEl={anchorEl}
                                                      handleClose={handleClose}/> :
                            <SidebarCategory category={category}
                                             expanded={expanded}
                                             handleChange={handleChange}
                                             sideBarWidth={state.sideBarWidth}
                                             toolStacks={state.toolStacks}
                                             handleSidebarToolClick={state.handleSidebarToolClick}
                                             handleSidebarAdditionalToolClick={state.handleSidebarAdditionalToolClick}
                            />
                    }) : ""}
                </div>
                <div className={classes.bottomMatter}>
                    <CurrentUser isCollapsed={isCollapsed}/>
                    <CustomerSelect isCollapsed={isCollapsed}/>
                </div>
            </Drawer>
        </div>
    );
}

