import jsonpath from "jsonpath";
import React, {useContext} from "react";
import PropTypes from "prop-types";
import {FreeformToolContext} from "../../JsonTool";
import {getColumnComponent, getColumnType, getDataSourceColumnNamesInOrder, skipColumn} from "../../ColumnsUtil";
import {DataSource, ServerColumn} from "../../classes";

export default function KvLayout(props: { meta: any, data: any }) {

    const [data, datasources, detailID, idColumnName] = useContext(FreeformToolContext);

    let obj        = data ? jsonpath.query(data, props.meta.value)[0] : null;
    let datasource = datasources.find((ds: DataSource) => props.meta.value.includes(ds.name))

    let columns       = props.meta.columns || 1
    let columnSpacing = props.meta.columnSpacing || 0

    let continuation: 'lines' | 'dots' | 'dashes' | 'none' | 'zebra' = props.meta.continuation || 'zebra'

    // lines, dots, dashes, colon with no spacing, zebrastriping

    const getKvLayout = (kvObject: any) => {
        if (!kvObject) return null;
        const chunk = (arr: any[], size: number) =>
            Array.from({length: Math.ceil(arr.length / size)}, (v, i) =>
                arr.slice(i * size, i * size + size)
            );

        let keys           = Object.keys(kvObject).filter((k: string) => {
            const mdTableColumn = datasource?.metadata?.columns?.find((c: any) => c.name.split("|")[0] === k);
            const columnType    = getColumnType(k, mdTableColumn?.type || "", datasource.metadata.columns);

            return !skipColumn(k, columnType) && !props.meta.skipKeys?.includes(k);
        }).sort((k1, k2) => {

            let columnNamesInOrder = getDataSourceColumnNamesInOrder(datasource)

            return columnNamesInOrder.indexOf(k1) - columnNamesInOrder.indexOf(k2);
        })
        let itemsPerColumn = Math.ceil(keys.length / columns)

        let keyArrays = chunk(keys, itemsPerColumn)

        return <div style={{
            margin      : props.meta.margin,
            border      : props.meta.border,
            padding     : props.meta.padding,
            borderRadius: props.meta.borderRadius,
            width       : props.meta.width,
            display     : "flex"
        }}>
            {keyArrays.map((ka, index) => getKvColumn(kvObject, keyArrays.length, ka, index))}
        </div>
    };

    const getKvColumn = (kvObject: any, keyArraysLength: number, ka: any[], index: number) =>
        <div style={{width: (100 / keyArraysLength) + "%", marginLeft: index > 0 ? columnSpacing : 0}}>
            {ka.map((k: string, i) => getValueDisplay(k, getValue(kvObject, k), i))}
        </div>;

    function getValue(kvObject: any, k: string) {
        let value = kvObject[k];

        if (value !== null) return value;

        const nullDisplay = props.meta.options?.nullDisplay as string | null | undefined;

        switch (nullDisplay) {
            case "HIDE":
                value = null;
                break
            default:
                value = nullDisplay
        }

        return value;
    }


    const getValueDisplay = (k: string, value: any, index: number) => {
        if (value === null) {
            return null;
        }

        let serverColumn = datasource.columns.find((c: ServerColumn) => c.title === k)
        let display      = serverColumn ? getColumnComponent(serverColumn).getDisplayValue(value) : value;
        return <KeyValueDisplay displayKey={k}
                                continuation={continuation}
                                index={index}
                                display={display}
                                margin={props.meta.spacing}/>
    };

    return getKvLayout(obj);
}

interface KvProps {
    displayKey: string
    continuation: 'lines' | 'dots' | 'dashes' | 'none' | 'zebra'
    index: number
    display: string
    margin: string
}

export function KeyValueDisplay({displayKey, continuation, index, display, margin}: KvProps) {

    const getBorderStyle = (cont: 'lines' | 'dots' | 'dashes' | 'none' | 'zebra') => {
        switch (cont) {
            case "lines":
                return '1px solid #aaa';
            case "dots":
                return '2px dotted #aaa';
            case "dashes":
                return '2px dashed #aaa';
            default:
                return '0px none';

        }
    }

    return <div key={displayKey}
                style={{
                    display        : 'flex',
                    backgroundColor: continuation === 'zebra' && index % 2 ? "#eee" : "inherit",
                    verticalAlign  : "bottom",
                    justifyContent : continuation === 'none' ? 'flex-start' : 'space-between',
                    margin         : margin,
                    // border        : props.meta.border
                }}>
        <div>{displayKey}{(continuation === 'none' && ':')}</div>

        {continuation !== 'none' && <div style={{
            flexGrow    : 1,
            borderBottom: getBorderStyle(continuation),
            margin      : "0 2px 4px 2px"
        }}></div>}
        <div style={{
            marginLeft: continuation === 'none' ? "10px" : 0,
            fontWeight: "bold",
            fontSize  : "16px",
            lineHeight: "18px"
        }}>{display}</div>
    </div>
}

KvLayout.propTypes = {
    meta: PropTypes.any.isRequired,
    data: PropTypes.any
}