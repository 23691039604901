import {ListItemButton, ListItemText} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

export function SidebarToolGroup(props: any) {
    return <ListItemButton key={props.componentKey + "_" + props.toolIndex}
                           id={props.toolCategoryName.replaceAll(" ", "_") + "__" + props.title.replaceAll(" ", "_") + "_sidebarButton"}
                           selected={props.selected}
                           onClick={() => {
                               props.handleSidebarToolClick();
                               if (props.handleClose) {
                                   props.handleClose();
                               }
                           }}>
        <ListItemText
            primary={props.title}
            disableTypography={true}
            className={props.classes.accordionListItem}/>
    </ListItemButton>;
}

SidebarToolGroup.propTypes = {
    title           : PropTypes.string.isRequired,
    toolCategoryName: PropTypes.string.isRequired,
    selected        : PropTypes.bool.isRequired,
    componentKey    : PropTypes.string.isRequired,
    toolIndex             : PropTypes.number.isRequired,
    handleSidebarToolClick: PropTypes.func.isRequired,
    classes               : PropTypes.any.isRequired,
    handleClose           : PropTypes.func
}