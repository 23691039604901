import {
    Filter,
    FilterCriterion,
    FrameState,
    GroupState,
    MuiTableColumn,
    ServerColumn,
    Sort,
    StackFrame,
    StackState,
    TabState,
    Tool,
    ToolCategory,
    ToolGroup,
    ToolStack,
    ToolState,
    ToolTab
} from "../classes";
import {v4 as uuidv4} from "uuid";
import {DESCRIPTIONS, DRILLDOWNS, NAVIGATION} from "../ToolsComponent";
import {getForms} from "./FormUtils";
import {getDescriptions} from "./LocalStorageUtil";
import _ from "lodash";
import {skipColumn} from "../ColumnsUtil";
import ProgressNoteDemo from "../components/ProgressNoteDemo";


export const additionalTools = [
    // {
    //     categoryName: "Admin",
    //     groupName   : "Tool Builder",
    //     component   : ToolBuilder
    // },
    {
        categoryName: "Admin",
        groupName   : "Progress Note AI",
        component   : ProgressNoteDemo
    }

]

export function createToolStacks(toolCategories: ToolCategory[], stackStates: StackState[], currentCustomerID: any) {
    const toolStacks = [] as ToolStack[]

    toolCategories.forEach((category: ToolCategory) => {
        category.groups?.forEach((group: ToolGroup) => {

            let stackState = stackStates?.find(ss => ss.key === `${category.name}|${group.name}`)

            if (stackState) {
                toolStacks.push(loadToolStackFromState(group, category, stackState, currentCustomerID))
            } else {
                toolStacks.push(createToolStack(group, category, currentCustomerID));
            }
        });
    })

    return toolStacks
}

function createToolStack(group: ToolGroup, category: ToolCategory, currentCustomerID: any): ToolStack {

    const toolGroupInstance = createToolGroupInstance(group, `categories|${category.name}|${group.name}`, currentCustomerID);

    return {
        uuid            : uuidv4(),
        key             : `${category.name}|${group.name}`,
        title           : toolGroupInstance!.name,
        stackFrames     : [createStackFrame(toolGroupInstance, 'GROUP')],
        toolCategoryName: category.name,
        selected        : false,
        lastUpdated     : new Date()
    };
}

function loadToolStackFromState(group: ToolGroup, category: ToolCategory, stackState: StackState, currentCustomerID: any): ToolStack {

    return {
        uuid            : uuidv4(),
        key             : stackState.key,
        title           : stackState.title,
        stackFrames     : stackState.frameStates.map((fs) => loadStackFrameFromState(fs, currentCustomerID)),
        toolCategoryName: category.name,
        selected        : stackState.selected,
        lastUpdated     : stackState.timestamp
    };

}

export function createStackFrame(toolOrGroup: Tool | ToolGroup, type: 'GROUP' | 'TOOL', detailID: any = null, idColumnName: any = null, searchStr: any = null, selected: boolean = false): StackFrame {
    return {
        uuid        : uuidv4(),
        toolOrGroup : toolOrGroup,
        detailID    : detailID,
        idColumnName: idColumnName,
        searchStr   : searchStr,
        type        : type,
        selected    : selected,
        lastUpdated : new Date()
    };
}

function loadStackFrameFromState(frameState: FrameState, currentCustomerID: any): StackFrame {
    return {
        uuid        : uuidv4(),
        toolOrGroup: frameState.type === 'GROUP' ? loadToolGroupFromState(frameState.state.key, frameState.state as GroupState, currentCustomerID) : loadToolFromState(frameState.state.key, frameState.state as ToolState, currentCustomerID),
        detailID    : frameState.detailID,
        idColumnName: frameState.idColumnName,
        searchStr   : frameState.searchStr,
        type        : frameState.type,
        selected    : frameState.selected,
        lastUpdated : frameState.timestamp
    };
}

export function createToolGroupInstance(toolGroup: ToolGroup, key: string, currentCustomerID: any, detailID: any = null, searchStr: string | null = null): ToolGroup {
    const newToolGroup = {...toolGroup}

    newToolGroup.key              = key
    newToolGroup.uuid             = uuidv4()
    newToolGroup.detailID         = detailID
    newToolGroup.searchStr        = searchStr
    newToolGroup.parsedTools      = toolGroup.tools?.map((t: string) => createToolInstance(t, currentCustomerID, detailID, searchStr)) || []
    newToolGroup.tabs             = toolGroup.tabs?.map((t: ToolTab) => createToolTabInstance(t, currentCustomerID, detailID, searchStr)) || []
    newToolGroup.selectedTabIndex = toolGroup.tabs?.findIndex(t => t.selected)

    newToolGroup.forms = getForms("GROUP_HEADER", key)

    if (newToolGroup.tabs && newToolGroup.tabs.length > 0 && newToolGroup.selectedTabIndex === -1) {
        newToolGroup.selectedTabIndex = 0
        newToolGroup.tabs[0].selected = true
    }

    initializeGridLayout(newToolGroup);

    return newToolGroup;
}


function loadToolGroupFromState(key: any, state: GroupState, currentCustomerID: any,): ToolGroup {
    let toolGroup = findToolGroupByKey(key)

    return {
        customTitle          : state.customTitle,
        description          : toolGroup.description,
        detailID             : state.detailID,
        idColumnName         : state.idColumnName,
        displayTextColumnName: toolGroup.displayTextColumnName,
        error                : false,
        gridLayout           : toolGroup.gridLayout,
        isLoading            : false,
        key                  : state.key,
        lastUpdated          : state.timestamp,
        forms                : state.forms,
        name                 : state.name,
        parsedGridLayout     : state.parsedGridLayout,
        parsedTools: toolGroup.tools?.map((t: string) => loadToolFromState(t, state.toolStates?.find(s => s.key.split('|')[0] === t.split('|')[0]), currentCustomerID)),
        searchStr            : state.searchStr,
        selected             : state.selected,
        selectedTabIndex     : state.selectedTabIndex,
        tabs       : toolGroup.tabs?.map((tb: ToolTab) => loadToolTabFromState(tb, state.tabStates?.find(s => s.name === tb.name), currentCustomerID)),
        toolData             : null,
        tools                : toolGroup.tools,
        uuid                 : uuidv4()
    };
}

export function createToolTabInstance(toolTab: ToolTab, currentCustomerID: any, detailID: any = null, searchStr: string | null = null): ToolTab {
    const newTab = {...toolTab} as ToolTab;

    newTab.uuid        = uuidv4()
    newTab.detailID    = detailID
    newTab.searchStr   = searchStr
    newTab.parsedTools = newTab.tools?.map((tool: string) => createToolInstance(tool, currentCustomerID, detailID, searchStr))
    newTab.tabs        = newTab.tabs?.map((tab: ToolTab) => createToolTabInstance(tab, currentCustomerID, detailID, searchStr)) || null

    initializeGridLayout(newTab);

    return newTab;
}


function loadToolTabFromState(toolTab: ToolTab, tabState: TabState | undefined, currentCustomerID: any,): ToolTab {

    if (!tabState) {
        return createToolTabInstance(toolTab, currentCustomerID)
    }

    return {
        customTitle          : tabState.customTitle,
        description          : toolTab.description,
        detailID             : tabState.detailID,
        idColumnName         : tabState.idColumnName,
        displayTextColumnName: toolTab.displayTextColumnName,
        error                : false,
        gridLayout           : toolTab.gridLayout,
        isLoading            : false,
        lastUpdated          : tabState.timestamp,
        name                 : toolTab.name,
        order                : toolTab.order,
        parsedGridLayout     : tabState.parsedGridLayout,
        parsedTools: toolTab.tools?.map((t: string) => loadToolFromState(t, tabState.toolStates?.find(s => s.key.split('|')[0] === t.split('|')[0]), currentCustomerID)),
        searchStr            : tabState.searchStr,
        selected             : tabState.selected,
        selectedTabIndex     : tabState.selectedTabIndex,
        toolData             : null,
        tools                : toolTab.tools,
        tabs       : toolTab.tabs?.map((t: ToolTab) => loadToolTabFromState(t, tabState.tabStates?.find(s => s.name === t.name), currentCustomerID)) || null,
        uuid                 : uuidv4()
    };
}

export function parseDefaultSorts(sortstr?: string): Sort[] {
    if (!sortstr) {
        return [];
    }
    return sortstr.split("=")[1].split("^").map((sortStr: string) => {
        const sortArr = sortStr.split("~");
        return {attr: sortArr[0], direction: sortArr[1]};
    })
}


export function parseDefaultFilters(filterstr?: string): Filter[] {
    if (!filterstr) {
        return [];
    }
    return filterstr.split("=")[1].split(",").map((segment: string) => {
        const [attr, comp, rawValue] = segment.split("~");
        let values: any[];
        if (rawValue.startsWith('[') && rawValue.endsWith(']')) {
            const withoutBrackets = rawValue.slice(1, -1);
            if (withoutBrackets.includes(',')) {
                values = withoutBrackets.split(',')
            } else {
                values = [withoutBrackets];
            }
        } else if (rawValue === 'CURRENT_YEAR') {
            values = [new Date().getFullYear()]
        } else if (rawValue === 'CURRENT_MONTH') {
            values = [new Date().getMonth()]
        } else {
            values = [rawValue]
        }
        const filter = new Filter(attr);
        values.forEach((value: any) => {
            filter.addCriteria(comp, value);
        });
        return filter;
    });
}

export function createToolInstance(tool: string, currentCustomerID: any, detailID: any = null, idColumnName: any = null, searchStr: any = null): Tool {
    let toolMetadata = tool.split("|")
    const toolName   = toolMetadata[0]
    const toolTitle  = toolMetadata.length > 1 ? toolMetadata[1] : toolName;

    const typestr  = toolMetadata.find(m => m.startsWith("TYPE="))
    const urlStr   = toolMetadata.find(m => m.startsWith("URL="))
    const toolType = typestr ? typestr.split("=")[1] : 'TABLE';

    const filterstr = toolMetadata.find(m => m.startsWith("FILTERS="))
    const sortstr   = toolMetadata.find(m => m.startsWith("SORTS="))

    const descriptions = getDescriptions()

    const myDescription = descriptions ? descriptions[toolName]?.description : null

    const newTool = {
        dbName               : toolName,
        key                  : null,
        columnStates         : null,
        url                  : urlStr,
        description          : myDescription ? myDescription.replaceAll(/\n/g, '  \n') : null, // From metadata
        defaultSorts: parseDefaultSorts(sortstr), // From metadata
        displayTextColumnName: null, // From metadata
        name                 : null, // From metadata
        toolType             : toolType,
        entityType           : null, // From metadata
        columns              : null, // From metadata
        forms                : null,
        mappedColumns        : null,

        title          : toolTitle,
        customTitle    : null,
        detailID       : detailID,
        idColumnName   : idColumnName,
        searchStr      : searchStr,
        lastUpdated    : new Date(),
        mermaidWidth   : 1000,
        refreshInterval: null,

        params    : {
            filters     : parseDefaultFilters(filterstr),
            sorts       : [],
            paging      : {page: 0, rows: 20},
            toolType    : toolType,
            entityType  : null,
            idColumnName: idColumnName
        },
        filterList: [],
        sortOrder   : {name: "asdf", direction: "none"},

        // From request

        pagingInfo: null,
        toolData  : null,
        chartType : null,
        chartTypes: [],
        options   : null,

        // Flags

        error              : false,
        isLoading          : false,
        initialized        : false,
        silentReload       : false,
        uuid               : uuidv4(),
        layouts            : null,
        datasources        : null,
        serverSideFiltering: false
    };

    return newTool
}

function loadToolFromState(key: any, state: ToolState | undefined, currentCustomerID: any): Tool {
    if (!state) {
        return createToolInstance(key, currentCustomerID)
    }

    let toolDefinitions = key.split("|")
    const toolName      = toolDefinitions[0]
    const toolTitle     = toolDefinitions.length > 1 ? toolDefinitions[1] : toolName;
    const typestr       = toolDefinitions.find((m: string) => m.startsWith("TYPE="))
    const urlStr        = toolDefinitions.find((m: string) => m.startsWith("URL="))
    const toolType      = typestr ? typestr.split("=")[1] : 'TABLE';
    const sortstr       = toolDefinitions.find((m: string) => m.startsWith("SORTS="))
    const myDescription = DESCRIPTIONS[toolName]?.description
    const loadedTool    = {
        dbName: toolName,
        key   : toolName,

        columnStates: state.columnStates,

        // From metadata

        description          : myDescription ? myDescription.replaceAll(/\n/g, '  \n') : null, // From metadata
        defaultSorts         : parseDefaultSorts(sortstr), // From metadata
        displayTextColumnName: null, // From metadata
        name                 : null, // From metadata
        url                  : urlStr, // From metadata
        toolType             : state.toolType, // From metadata
        entityType           : state.entityType, // From metadata
        columns              : null, // From metadata
        forms                : null,
        mappedColumns        : null,

        // From state

        title          : state.customTitle || toolTitle, // From metadata if null
        customTitle    : state.customTitle,
        detailID       : state.detailID,
        idColumnName   : state.idColumnName,
        searchStr      : state.searchStr,
        lastUpdated    : state.timestamp,
        mermaidWidth   : state.mermaidWidth || 1000,
        refreshInterval: state.refreshInterval,
        params         : {
            filters     : state.filters ? [...state.filters] : [],
            sorts       : state.sorts ? [...state.sorts] : [],
            paging      : state.paging ? {...state.paging} : {page: 0, rows: 20},
            toolType    : state.toolType,
            entityType  : state.entityType,
            idColumnName: state.idColumnName
        },

        filterList: state.filterList ? [...state.filterList] : [],
        sortOrder: getSortOrder(state),

        // From request

        pagingInfo: null,
        toolData  : null,
        chartType : null,
        chartTypes: [],
        options   : null,

        // Flags

        error              : false,
        isLoading          : false,
        initialized        : false,
        silentReload       : false,
        uuid               : uuidv4(),
        layouts            : state.layouts,
        datasources        : state.datasources,
        serverSideFiltering: false

    };

    return loadedTool;
}

export function findToolGroupByKey(key: any): ToolGroup {
    let keyStrs = key.split("|")

    const baseLocationName   = keyStrs[0];
    const categoryOrToolName = keyStrs[1];
    const groupName          = keyStrs[2];

    let toolGroup = null

    switch (baseLocationName) {
        case "categories":
            let cat = NAVIGATION.find((c: any) => c.name === categoryOrToolName) as ToolCategory

            if (!cat) {
                throw new Error("Invalid category " + categoryOrToolName)
            }

            toolGroup = cat.groups.find(g => g.name === groupName)

            break
        case "drilldowns":
            let drilldowns = DRILLDOWNS[categoryOrToolName]

            if (!drilldowns) {
                throw new Error("Invalid drilldown tool " + categoryOrToolName)
            }

            toolGroup = drilldowns.find((dd: any) => typeof dd.group !== 'string' && dd.group.name === groupName)?.group


            break
        default:
            throw new Error("Invalid key " + key)
    }

    if (!toolGroup) {
        throw new Error("Invalid group " + groupName)
    }

    toolGroup.key = key

    return toolGroup
}


function compactLayout(groupOrTab: ToolGroup | ToolTab) {
    groupOrTab.parsedGridLayout.sort((a, b) => parseInt(a.y) - parseInt(b.y) || parseInt(a.x) - parseInt(b.x))

    let currentRowIndex    = 0
    let maxRowItemHeight   = 0
    let currentColumnIndex = 0

    for (let i = 0; i < groupOrTab.parsedGridLayout.length; i++) {
        let currentItem  = groupOrTab.parsedGridLayout[i]
        let previousItem = i === 0 ? null : groupOrTab.parsedGridLayout[i - 1]

        if (!previousItem) {
            // first item - appears in upper left corner
            currentItem.x = 0
            currentItem.y = 0

            if (currentItem.h > maxRowItemHeight) {
                maxRowItemHeight = currentItem.h
            }

        } else {

            if (previousItem.y !== currentItem.y) {
                // row change
                currentRowIndex += maxRowItemHeight
                maxRowItemHeight   = 0
                currentColumnIndex = 0
            }

            if (currentItem.h > maxRowItemHeight) {
                maxRowItemHeight = currentItem.h
            }

            currentItem.x = currentColumnIndex
            currentItem.y = currentRowIndex

        }

        currentColumnIndex += currentItem.w

    }
}

function initializeGridLayout(groupOrTab: ToolGroup | ToolTab) {


    if (groupOrTab.gridLayout) {
        if (!groupOrTab.parsedGridLayout) {
            groupOrTab.parsedGridLayout = []
        }
        Object.entries(groupOrTab.gridLayout).forEach((entry: [string, any]) => {
            const name = entry[0]
            const hwxy = entry[1].split(":")[1].split(",")

            groupOrTab.parsedGridLayout.push({
                "i"     : name,
                "h"     : parseInt(hwxy[0]),
                "w"     : parseInt(hwxy[1]),
                "x"     : parseInt(hwxy[2]),
                "y"     : parseInt(hwxy[3]),
                "moved" : false,
                "static": false
            })
        })
        compactLayout(groupOrTab);

    } else {
        groupOrTab.parsedGridLayout = groupOrTab.parsedTools?.map((t: Tool) => ({
            "i"     : t.dbName,
            "h"     : 12,
            "w"     : 12,
            "x"     : 0,
            "y"     : 0,
            "moved" : false,
            "static": false
        }))
    }


}


export function initializeToolFilterList(tool: Tool): any {
    let filterableColumns = tool.columns?.filter((c) => !skipColumn(c.attr, c.type.name)) || []
    if (!filterableColumns || (tool.filterList && tool.filterList.length === filterableColumns.length)) {
        return tool.filterList;
    }

    const filterList = filterableColumns.map(() => [])

    if (tool.toolType === 'CHART') {
        if (tool.params.filters.length > 0) {
            tool.params.filters.forEach((filter: Filter) => initializeDefaultFilter(filterableColumns, filter, filterList))
        } else {
            let filters = filterableColumns.filter((sc: ServerColumn) => sc.default !== null && sc.default !== undefined).map((sc: ServerColumn) => {
                let filter = new Filter(sc.attr)
                filter.addCriteria('eq', sc.default)
                return filter
            })
            filters.forEach((filter: Filter) => initializeDefaultFilter(filterableColumns, filter, filterList))
        }


    } else {
        tool.params.filters.forEach((filter: Filter) => initializeDefaultFilter(filterableColumns, filter, filterList))
    }

    return filterList
}

function initializeDefaultFilter(serverColumns: ServerColumn[], filter: Filter, filterList: any) {
    let desiredIndex = -1;
    let currentIndex = 0;

    for (let i = 0; i < serverColumns.length; i++) {
        const c = serverColumns[i];
        const attr = c.attr;
        if (skipColumn(attr, c.type.name)) {
            continue;
        }
        if (attr === filter.attr) {
            desiredIndex = currentIndex;
            break;
        }

        currentIndex++;
    }

    if (desiredIndex < 0) {
        return;
    }

    filter.criteria.forEach((filterCriterion: FilterCriterion) => {
        if (Array.isArray(filterCriterion.value)) {
            filterCriterion.value.forEach(v => filterList[desiredIndex].push(v))
        } else {
            filterList[desiredIndex].push(filterCriterion.value);
        }
    });
}


export function getStackState(stack: ToolStack): StackState {
    return {
        key        : stack.key,
        title      : stack.title,
        selected   : stack.selected,
        frameStates: stack.stackFrames.map(getStackFrameState),
        timestamp  : new Date()
    }
}

function getStackFrameState(stackFrame: StackFrame): FrameState {
    let objectState: GroupState | ToolState;

    if (stackFrame.type === "GROUP") {
        objectState = getGroupState(stackFrame.toolOrGroup as ToolGroup);
    } else {
        objectState = getToolState(stackFrame.toolOrGroup as Tool);
    }

    return {
        key         : stackFrame.toolOrGroup.name,
        selected    : stackFrame.selected,
        state       : objectState,
        type        : stackFrame.type,
        detailID    : stackFrame.detailID,
        idColumnName: stackFrame.idColumnName,
        searchStr   : stackFrame.searchStr,
        timestamp   : new Date()
    }
}

function getGroupState(toolGroup: ToolGroup): GroupState {
    return {
        name            : toolGroup.name,
        key             : toolGroup.key,
        selected        : toolGroup.selected,
        selectedTabIndex: toolGroup.selectedTabIndex,
        customTitle     : toolGroup.customTitle,
        detailID        : toolGroup.detailID,
        forms           : toolGroup.forms,
        idColumnName    : toolGroup.idColumnName,
        searchStr       : toolGroup.searchStr,
        parsedGridLayout: toolGroup.parsedGridLayout,
        toolStates      : toolGroup.parsedTools?.map(getToolState),
        tabStates: toolGroup.tabs?.map((t, i) => getTabState(t, i, toolGroup.selectedTabIndex)),
        timestamp       : new Date()
    }
}

function getTabState(toolTab: ToolTab, index: number, selectedTabIndex: number): TabState {
    return {
        name            : toolTab.name,
        selected : toolTab.selected !== undefined && toolTab.selected !== null ? toolTab.selected : index === selectedTabIndex,
        customTitle     : toolTab.customTitle,
        detailID        : toolTab.detailID,
        idColumnName    : toolTab.idColumnName,
        searchStr       : toolTab.searchStr,
        parsedGridLayout: toolTab.parsedGridLayout,
        selectedTabIndex: toolTab.selectedTabIndex,
        tabStates: toolTab.tabs?.map((t, i) => getTabState(t, i, toolTab.selectedTabIndex)) || null,
        toolStates      : toolTab.parsedTools?.map(getToolState),
        timestamp       : new Date()
    }
}

function getToolState(tool: Tool): ToolState {
    return {
        key            : tool.dbName + "|" + tool.title,
        toolType       : tool.toolType,
        entityType     : tool.entityType,
        customTitle    : tool.customTitle,
        columnStates: tool.toolType === 'TABLE' ? tool.mappedColumns?.map(getColumnState) || [] : null,
        mermaidWidth   : tool.mermaidWidth,
        refreshInterval: tool.refreshInterval,
        filterList     : tool.filterList,
        filters        : tool.params?.filters,
        sorts          : tool.params?.sorts,
        paging         : tool.params?.paging,
        detailID       : tool.detailID,
        idColumnName   : tool.idColumnName,
        searchStr      : tool.searchStr,
        layouts     : tool.layouts,
        datasources : tool.datasources,
        timestamp      : new Date()
    };
}

function getColumnState(muiTableColumn: MuiTableColumn) {
    let isVisible: number;

    if (muiTableColumn.visible !== null && muiTableColumn.visible !== undefined) {
        isVisible = muiTableColumn.visible ? 1 : 0;
    } else {
        isVisible = 1;
    }

    let actualOrder: number;

    const definedOrder = muiTableColumn.order;
    if (muiTableColumn.actualOrder !== undefined && muiTableColumn.actualOrder !== null) {
        actualOrder = muiTableColumn.actualOrder;
    } else {
        actualOrder = definedOrder;
    }

    return [definedOrder, actualOrder, isVisible];
}

export function getOrCreateDrilldownStackFrame(toolStack: ToolStack,
                                               currentStackFrame: StackFrame,
                                               drilldownGroup: any,
                                               drilldownDetailID: any,
                                               idColumnName: any,
                                               currentCustomerID: any,
                                               drilldownSearchStr: string | null,
                                               drilldownTitle: string | null) {
    const currentStackFrameIndex = toolStack.stackFrames.indexOf(currentStackFrame);
    const newFrameIndex          = currentStackFrameIndex + 1;

    let newStackFrame = null
    let reload        = true

    if (toolStack.stackFrames.length >= newFrameIndex) {
        let existingStackFrame = toolStack.stackFrames[newFrameIndex];

        if (existingStackFrame && existingStackFrame.toolOrGroup.name === drilldownGroup.name) {
            newStackFrame = existingStackFrame

            let sameDetailIDs  = newStackFrame.detailID === drilldownDetailID;
            let sameSearchStrs = newStackFrame.searchStr === drilldownSearchStr;

            if (sameDetailIDs && sameSearchStrs) {
                reload = false
            } else {
                // If it is not the same, then we need to slice all stackFrames off the stack that come after this one and reload
                toolStack.stackFrames = toolStack.stackFrames.slice(0, toolStack.stackFrames.indexOf(existingStackFrame) + 1)
                setDetailIDandSearchStr(newStackFrame, drilldownDetailID, drilldownSearchStr, idColumnName);

                if (drilldownTitle) {
                    newStackFrame.toolOrGroup.customTitle = drilldownTitle;
                }

            }
        } else {
            toolStack.stackFrames = toolStack.stackFrames.slice(0, toolStack.stackFrames.indexOf(currentStackFrame) + 1)
        }
    }

    if (!newStackFrame) {
        const toolGroup = createToolGroupInstance(drilldownGroup, drilldownGroup.key, currentCustomerID, drilldownDetailID, drilldownSearchStr)
        if (drilldownTitle) {
            toolGroup.customTitle = drilldownTitle
        }
        newStackFrame = createStackFrame(toolGroup, "GROUP", drilldownDetailID, drilldownSearchStr, true);
        setDetailIDandSearchStr(newStackFrame, drilldownDetailID, drilldownSearchStr, idColumnName);
        toolStack.stackFrames.push(newStackFrame)
    }
    return {newStackFrame, reload};
}


function setDetailIDandSearchStr(stackFrame: StackFrame, detailID: any, searchStr: string | null, idColumnName: string | null) {
    stackFrame.detailID                 = detailID
    stackFrame.idColumnName             = idColumnName
    stackFrame.searchStr                = searchStr
    stackFrame.toolOrGroup.detailID     = detailID;
    stackFrame.toolOrGroup.idColumnName = idColumnName;
    stackFrame.toolOrGroup.searchStr    = searchStr;
    (stackFrame.toolOrGroup as ToolGroup).parsedTools?.forEach(t => {
        t.detailID     = detailID
        t.idColumnName = idColumnName
        t.searchStr    = searchStr
    });

    (stackFrame.toolOrGroup as ToolGroup).tabs?.forEach((t: ToolTab) => setTabDetailIDandSearchStr(t, detailID, searchStr, idColumnName));
}

function setTabDetailIDandSearchStr(tab: ToolTab, detailID: any, searchStr: string | null, idColumnName: string | null) {
    tab.detailID     = detailID
    tab.idColumnName = idColumnName
    tab.searchStr    = searchStr
    tab.parsedTools?.forEach(t => {
        t.detailID     = detailID
        t.idColumnName = idColumnName
        t.searchStr    = searchStr
        t.toolData     = null
    })
    tab.tabs?.forEach((t: ToolTab) => setTabDetailIDandSearchStr(t, detailID, searchStr, idColumnName))

}

function getSortOrder(state: ToolState) {
    if (state.sorts?.length > 0) {
        let sort = state.sorts[0]
        return {name: sort.attr, direction: sort.direction.toLowerCase()}
    } else {
        return {name: "asdf", direction: "none"}
    }
}

function isTabStateEqual(b1: TabState, b2: TabState): string | null {
    if (b1.selected !== b2.selected) return 'TabState.selected';
    if (b1.customTitle !== b2.customTitle) return 'TabState.customTitle';
    if (b1.detailID !== b2.detailID) return 'TabState.detailID';
    if (b1.searchStr !== b2.searchStr) return 'TabState.searchStr';
    if (b1.selectedTabIndex !== b2.selectedTabIndex) return 'TabState.selectedTabIndex';
    if (!_.isEqual(b1.parsedGridLayout, b2.parsedGridLayout)) return 'TabState.parsedGridLayout';

    if (b1.toolStates) {
        for (const t1 of b1.toolStates) {
            let t2 = b2.toolStates?.find((t) => t.key === t1.key)
            if (!t2) {
                return 'TabState.ToolState not exist ' + t1.key
            }
            const errorMessage = isToolStateEqual(t1, t2);
            if (errorMessage) return errorMessage
        }
    }

    if (b1.tabStates) {
        for (const bb1 of b1.tabStates) {
            let bb2 = b2.tabStates?.find((bb) => bb.name === bb1.name)
            if (!bb2) {
                return 'TabState.TabState not exist ' + bb1.name
            }
            const errorMessage = isTabStateEqual(bb1, bb2);
            if (errorMessage) return errorMessage
        }
    }

    return null

}

function isGroupStateEqual(g1: GroupState, g2: GroupState): string | null {

    if (g1.key !== g2.key) return 'GroupState.key';
    if (g1.selected !== g2.selected) return 'GroupState.selected';
    if (g1.selectedTabIndex !== g2.selectedTabIndex) return 'GroupState.selectedTabIndex';
    if (g1.customTitle !== g2.customTitle) return 'GroupState.customTitle';
    if (g1.detailID !== g2.detailID) return 'GroupState.detailID';
    if (g1.searchStr !== g2.searchStr) return 'GroupState.searchStr';
    if (!_.isEqual(g1.parsedGridLayout, g2.parsedGridLayout)) return 'GroupState.parsedGridLayout';

    if (g1.toolStates) {
        for (const t1 of g1.toolStates) {
            let t2 = g2.toolStates?.find((t) => t.key === t1.key)
            if (!t2) {
                return 'GroupState.ToolState not exist ' + t1.key
            }
            let errorMessage = isToolStateEqual(t1, t2)
            if (errorMessage) return errorMessage
        }
    }
    if (g1.tabStates) {
        for (const t1 of g1.tabStates) {
            let t2 = g2.tabStates?.find((t) => t.name === t1.name)
            if (!t2) {
                return 'GroupState.TabState not exist ' + t1.name
            }
            let errorMessage = isTabStateEqual(t1, t2)
            if (errorMessage) return errorMessage
        }
    }

    return null

}

function isToolStateEqual(state: ToolState, state2: ToolState): string | null {
    if (state.customTitle !== state2.customTitle) return 'ToolState.customTitle';
    if (state.mermaidWidth !== state2.mermaidWidth) return 'ToolState.mermaidWidth';
    if (state.refreshInterval !== state2.refreshInterval) return 'ToolState.refreshInterval';
    if (state.detailID !== state2.detailID) return 'ToolState.detailID';
    if (state.searchStr !== state2.searchStr) return 'ToolState.searchStr';
    if (!_.isEqual(state.columnStates, state2.columnStates)) {
        return 'ToolState.columnStates';
    }
    if (!_.isEqual(state.filters, state2.filters)) return 'ToolState.filters';
    if (!_.isEqual(state.sorts, state2.sorts)) return 'ToolState.sorts';
    if (!_.isEqual(state.paging, state2.paging)) return 'ToolState.paging';

    return null;
}

function isFrameStateEqual(f1: FrameState, f2: FrameState): string | null {

    if (f1.selected !== f2.selected) {
        return 'FrameState.selected';
    }
    if (f1.detailID !== f2.detailID) {
        return 'FrameState.detailID';
    }
    if (f1.searchStr !== f2.searchStr) {
        return 'FrameState.searchStr';
    }
    if (f1.type === 'GROUP') {
        let errorMessage = isGroupStateEqual(f1.state as GroupState, f2.state as GroupState)
        if (errorMessage) return errorMessage
    }
    if (f1.type === 'TOOL') {
        let errorMessage = isToolStateEqual(f1.state as ToolState, f2.state as ToolState)
        if (errorMessage) return errorMessage
    }

    return null

}

export function isStackStateEqual(s1: StackState, s2: StackState | null | undefined): string | null {

    if (!s2) {
        return 'StackState not exist'
    }
    if (s1.selected !== s2.selected) {
        return 'StackState.selected'
    }

    for (const f1 of s1.frameStates) {
        let f2 = s2.frameStates.find((f) => f.key === f1.key)
        if (!f2) {
            return 'StackState.FrameState not exist ' + f1.key;
        }
        let errorMessage = isFrameStateEqual(f1, f2);
        if (errorMessage) {
            return errorMessage
        }
    }

    return null


}
    
    