import React, {useContext, useState} from "react";
import {doPost} from "../util/RestUtil";
import {NLP_URL} from "../util/RestRoutes";
import {MyDataContext} from "../ToolsComponent";
import Button from "@mui/material/Button";
import {
    CircularProgress,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Slider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    Typography
} from "@mui/material";
import {themeColor1} from "../styles/getMuiTheme";
import TextField from "@mui/material/TextField";
import TableRow from "@mui/material/TableRow";


export default function ProgressNoteDemo(props: any) {
    const [state, setState] = useContext(MyDataContext);

    const [progressNote, setProgressNote] = useState(null as any)
    const [icdResult, setIcdResult]       = useState(null as any)
    const [loading, setLoading]           = useState(false as boolean)
    const [formData, setFormData]         = useState({
        age         : 70,
        gender      : "F",
        eligibility : "CFA",
        orec        : "0",
        medicaid    : "True",
        progressNote: null as any
    });

    const handleFormChange = (event: any) => {
        const {name, value} = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        console.log('Form Data:', formData);
        setLoading(true)
        const response = await doPost(NLP_URL, formData) as any
        setIcdResult(response.data)
        setLoading(false)

    };

    const handleSliderChange = (event: any, newValue: any) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            age: newValue,
        }));
    };

    function handleExampleSelect(event: any) {
        setFormData((prevFormData) => ({
            ...prevFormData,
            progressNote: event.target.value,
        }));


    }


    const exampleTexts = [
        "Select Sample Text",
        "A 36-year-old patient with no significant medical history presented with acute deep venous thrombosis in the right lower extremity and bilateral pulmonary embolism. The patient was on intravenous heparin, complicated by acute renal failure. The patient, who works as a sales representative involving extensive travel, experienced acute dyspnea and syncope. Further investigations revealed a nonocclusive right popliteal artery thrombosis, multiple pulmonary emboli, and a possible renal infarct. With no family history of hypercoagulable conditions, the patient denies recent injury or calf symptoms. Medical and surgical history is unremarkable. The physical exam shows a robust individual with no evident signs of clotting, and laboratory findings indicate leukocytosis, possibly a reactive response."
    ]

    return <Container style={{width: "calc(100vw - " + state.sideBarWidth + "px)"}}>
        <Typography variant={"h4"} style={{marginBottom: "40px"}}>Progress Note AI Demo</Typography>
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center" style={{marginTop: "40px"}}>
                <Grid item xs={12} sm={12} md={10} style={{marginBottom: "10px"}}>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Examples</InputLabel>
                        <Select onChange={handleExampleSelect} fullWidth>
                            {exampleTexts.map((text: string) => {
                                return <MenuItem
                                    id={text.replaceAll(" ", "_")}
                                    key={text.replaceAll(" ", "_")}
                                    value={text}>{text}</MenuItem>

                            })}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={0} sm={0} md={2}>
                </Grid>

                <Grid item xs={12} sm={6} md={2}>

                    <FormControl fullWidth margin="normal">
                        <InputLabel id="demo-simple-select-label">Age</InputLabel>
                        <Slider
                            value={formData.age}
                            onChange={handleSliderChange}
                            aria-labelledby="age-slider"
                            step={10}
                            marks
                            min={20}
                            max={90}
                            valueLabelDisplay="on"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <FormControl fullWidth margin={"normal"}>
                        <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                        <Select name="gender"
                                value={formData.gender}
                                onChange={handleFormChange}
                                label="Gender"
                        >
                            {["F", "M"].map((l) => {
                                return <MenuItem
                                    id={l.replaceAll(" ", "_")}
                                    key={l.replaceAll(" ", "_")}
                                    value={l}>{l}</MenuItem>
                            })}

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>

                    <FormControl fullWidth margin={"normal"}>
                        <InputLabel id="demo-simple-select-label">Eligibility</InputLabel>

                        <Select name="eligibility"
                                value={formData.eligibility}
                                onChange={handleFormChange}
                                label="Eligibility"
                        >
                            {[
                                "CFA",
                                "CFD",
                                "CNA",
                                "CND",
                                "CPA",
                                "CPD",
                                "INS",
                            ].map((l) => {
                                return <MenuItem
                                    id={l.replaceAll(" ", "_")}
                                    key={l.replaceAll(" ", "_")}
                                    value={l}>{l}</MenuItem>
                            })}

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>

                    <FormControl fullWidth margin={"normal"}>
                        <InputLabel id="demo-simple-select-label">OREC</InputLabel>

                        <Select name="orec"
                                value={formData.orec}
                                onChange={handleFormChange}
                                label="OREC"
                        >
                            {[
                                "0",
                                "1",
                                "2",
                                "3"
                            ].map((l) => {
                                return <MenuItem
                                    id={l.replaceAll(" ", "_")}
                                    key={l.replaceAll(" ", "_")}
                                    value={l}>{l}</MenuItem>
                            })}

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>

                    <FormControl fullWidth margin={"normal"}>
                        <InputLabel id="demo-simple-select-label">Medicaid</InputLabel>

                        <Select name="medicaid"
                                value={formData.medicaid}
                                onChange={handleFormChange}
                                label="Medicaid"
                        >
                            <MenuItem value={"True"}>True</MenuItem>
                            <MenuItem value={"False"}>False</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={10}>
                    <FormControl fullWidth margin={"normal"}>
                        <TextField
                            fullWidth
                            margin="normal"
                            name="progressNote"
                            label="Progress Note"
                            multiline
                            value={formData.progressNote}
                            onChange={handleFormChange}
                        />

                    </FormControl>

                </Grid>
            </Grid>


            <Button type="submit" variant="contained" color="primary">
                Extract ICD-10 Codes
            </Button>

        </form>
        <div style={{marginTop: "10px"}}>
            {/*<pre>{JSON.stringify(icdResult, null, 2)}</pre>*/}
            {loading && <CircularProgress style={{color: themeColor1.hexa()}}/>}

            {!loading && icdResult !== null &&
                <div style={{padding: "10px", border: "1px solid black", borderRadius: "6px"}}>
                    <Typography variant={"h5"}>Results:</Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{fontWeight: "bold"}}>Text</TableCell>
                                <TableCell style={{fontWeight: "bold"}}>ICD-10</TableCell>
                                <TableCell style={{fontWeight: "bold"}}>Assertion</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {icdResult[0]?.Extracted_Entities_vs_ICD_Codes?.map((result: any) =>
                                <TableRow>
                                    <TableCell>{result[0]}</TableCell>
                                    <TableCell>{result[1]}</TableCell>
                                    <TableCell>{result[2]}</TableCell>
                                </TableRow>)}

                        </TableBody>
                    </Table>
                </div>}
        </div>

    </Container>


}