import {styled} from "@mui/styles";
import TextField from "@mui/material/TextField";
import React from "react";
import {CircularProgress} from "@mui/material";
import {KeyValueDisplay} from "../../freeform/KvLayout";

export const CustomTextField = styled((props: any) => {
    const styles = {margin: "22px 0 5px 0"};
    return props.isReadOnly ? <KeyValueDisplay continuation={'lines'}
                                               display={`${props.value}`}
                                               displayKey={props.label}
                                               index={0}
                                               margin={"0"}/> : <><TextField
        {...props}
        sx={styles}
        inputProps={{...props.inputProps, id: props.id}}
        InputLabelProps={{shrink: false}}
    />{props.validating && <span>validating...<CircularProgress size={16}/></span>}</>
})(({theme}) => ({}));