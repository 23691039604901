import axios from "axios";
import {DataSource, Tool} from "../classes";
import {TOOLS_URL} from "./RestRoutes";
import {getToolMetadata, setToolMetadata} from "./LocalStorageUtil";
import {parseDefaultFilters} from "./ToolUtils";
import {getServerColumnsFromToolMetadata} from "../ColumnsUtil";

let requests = {} as any

export function doGet(path: string, config: any = null, customerObjectID: string | null = null, retries: number = 5) {
    return doRestCall('GET', path, null, config, customerObjectID, retries)
}

export function doPost(path: string, data: any, config: any = null, customerObjectID: string | null = null, retries: number = 5) {
    return doRestCall('POST', path, data, config, customerObjectID, retries)
}

export function doDelete(path: string, config: any = null, customerObjectID: string | null = null, retries: number = 5) {
    return doRestCall('DELETE', path, null, config, customerObjectID, retries)
}

export async function doRestCall(method: "GET" | "POST" | "DELETE", path: string, data: any, config: any = null, customerObjectID: string | null = null, retries: number = 5) {
    return new Promise(async (resolve, reject) => {

        try {
            if (customerObjectID) {
                if (!config) {
                    config = {params: {customerObjectID: customerObjectID}}
                } else if (!config.params) {
                    config = {...config, params: {customerObjectID: customerObjectID}}
                } else {
                    config.params = {...config.params, customerObjectID: customerObjectID}
                }
            }

            // @ts-ignore
            let existingRequest = requests[path]

            if (existingRequest) {
                existingRequest.abort()
                // @ts-ignore
                requests[path] = null
            }

            const controller = new AbortController();

            // @ts-ignore
            requests[path] = controller

            if (!config) {
                config = {}
            }

            let signal = controller.signal

            path              = path.replaceAll(/PATH_UNIQUE_KEY=.*###/g, '')
            const finalConfig = {...config, signal, 'axios-retry': {retries: retries}};
            const url = path.startsWith("http") ? path : `${process.env.REACT_APP_API_URL}/${path}`;

            let resp

            switch (method) {
                case "GET":
                    resp = await axios.get(url, finalConfig)
                    break;
                case "POST":
                    resp = await axios.post(url, data, finalConfig)
                    break;
                case "DELETE":
                    resp = await axios.delete(url, finalConfig)
                    break;
            }
            requests[path] = null
            resolve(resp);


        } catch (e) {
            console.error(e)
            reject(e)
        }

    });

}

export async function doToolMetadataRequest(tool: Tool, config: any, customerObjectID: string) {
    try {
        let resp

        if (tool.url) {
            resp = null
        } else {
            const finalConfig = {...config, params: {...config.params, metadata: 1}};
            resp              = await doPost(`${TOOLS_URL}/${tool.dbName}`, tool.params, finalConfig, customerObjectID) as any;
        }

        return [resp?.data, resp];
    } catch (e) {
        throw e
    }

}


export async function getFreeformToolMetadata(tool: Tool, customerObjectID: string, testMetadata: any = null) {
    let toolMetadata = testMetadata ? testMetadata : getToolMetadata(tool.dbName)

    if (!toolMetadata) {
        toolMetadata = (await doToolMetadataRequest(tool, {params: {count: 0}}, customerObjectID) as any)[0]
        setToolMetadata(tool.dbName, toolMetadata)
    }

    let newDatasources        = toolMetadata.datasources;
    let newLayouts            = toolMetadata.layouts;
    let displayTextColumnName = toolMetadata.displayTextColumnName;

    newDatasources.forEach((ds: DataSource) => {
        const filterstr = ds.source.split("|").find(x => x.startsWith("FILTERS="))
        const filters   = filterstr ? parseDefaultFilters(filterstr) : []
        if (!ds.params) {
            ds.params = {
                filters     : [],
                sorts       : [],
                paging      : {page: 0, rows: 20},
                entityType  : ds.entityType,
                toolType    : 'TABLE', // TODO :deprecate for datasources
                idColumnName: null
            }
        }

        ds.params.filters = filters
    })


    const values    = newDatasources?.map((ds: any) => {

        let metadata = getToolMetadata(ds.source.split('|')[0])

        if (metadata) {

            return new Promise(async (resolve, reject) => {
                try {
                    resolve({data: metadata});
                } catch (e) {
                    console.error(e)
                    reject(e)
                }
            });
        }

        return doPost(`${TOOLS_URL}/${ds.source.split('|')[0]}PATH_UNIQUE_KEY=${ds.name}###`, {
            filters     : [],
            sorts       : [],
            paging      : {},
            entityType  : "V",
            toolType    : "TABLE",
            idColumnName: null
        }, {params: {count: 0, metadata: 1}}, customerObjectID);
    });
    const responses = await Promise.all(values) as any;
    for (let i = 0; i < newDatasources.length; i++) {
        const datasourceMetadata = responses[i].data;
        const datasource         = newDatasources[i];
        datasource.metadata      = datasourceMetadata
        datasource.columns       = getServerColumnsFromToolMetadata(datasourceMetadata)
        datasource.entityType    = datasourceMetadata.type
        setToolMetadata(datasource.source.split('|')[0], datasourceMetadata)
    }
    return [newDatasources, newLayouts, displayTextColumnName]
}