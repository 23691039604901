import {getColumnComponentByType} from "../../../../ColumnsUtil";
import {CellMeasurer} from "react-virtualized";
import React from "react";
import PropTypes from "prop-types";
import {makeStyles} from "tss-react/mui";
import {tableHeaderBGColor, tableHeaderBorderColor, themeColor1} from "../../../../styles/getMuiTheme";

const useStyles = makeStyles({name: 'MUIDataTableHeadCell'})(theme => ({
    root: {},
    fixedHeader: {
        position: 'sticky',
        top: '0',
        zIndex: 100,
        backgroundColor: tableHeaderBGColor.hexa(),
        borderRight: "1px solid " + tableHeaderBorderColor.hexa(),
        borderTop: "1px solid " + tableHeaderBorderColor.hexa(),
        borderBottom: "1px solid " + tableHeaderBorderColor.hexa(),
        padding: "0",
        width: "1px",
        "&:last-child": {
            width: "100% !important",
            backgroundColor: tableHeaderBGColor.hexa(),
        }

    },
    tooltip: {
        cursor: 'pointer',
    },
    filterPaper: {
        maxWidth: '50%',
        minWidth: '200px'
    },
    filterCloseIcon: {
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 100,
    },
    mypopper: {
        '&[data-x-out-of-boundaries]': {
            display: 'none',
        },
    },
    data: {
        display: 'inline-block',
    },
    sortAction: {
        display: 'flex',
        cursor: 'pointer',
    },
    dragCursor: {
        cursor: 'grab',
    },
    sortLabelRoot: {
        height: '20px',
    },
    sortActive: {
        color: theme.palette.text.primary,
    },
    toolButton: {
        borderRadius: "0",
        width: "100%",
        height: "100%",
        justifyContent: "left",
        textTransform: 'none',
        margin: 0,
        '&:hover': {
            backgroundColor: themeColor1.fade(0.9).hexa(),
            color: themeColor1.hexa()
        },
        minWidth: 0,
        paddingLeft: '0',
        paddingRight: '8px',
    },
    filterIcon: {
        borderRadius: "0",
        '&:hover': {
            backgroundColor: themeColor1.fade(0.9).hexa(),
            color: themeColor1.hexa()
        },
    },
    contentWrapper: {
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: themeColor1.fade(0.9).hexa(),
            color: themeColor1.hexa()
        },
    },
    contentWrapperHover: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: themeColor1.fade(0.9).hexa(),
        color: themeColor1.hexa()
    },
    hintIconAlone: {
        marginTop: '-3px',
        marginLeft: '3px',
    },
    hintIconWithSortIcon: {
        marginTop: '-3px',
    },
}));

export default function TableCellVirtualized({
                                                 cellKey,
                                                 parent,
                                                 style,
                                                 rowIndex,
                                                 columnIndex,
                                                 cellMeasurerCache,
                                                 serverColumn,
                                                 children,
                                                 handleCellClick,
                                                 hoveredRowIndex
                                             }) {
    let {classes} = useStyles()
    let columnDefinition = getColumnComponentByType(serverColumn.type.name)


    return <CellMeasurer cache={cellMeasurerCache}
                         columnIndex={columnIndex}
                         key={cellKey}
                         parent={parent}
                         rowIndex={rowIndex}>
        {({registerChild}) => (
            <div key={cellKey}
                 id={cellKey}
                 onClick={() => handleCellClick(rowIndex, columnIndex)}
                 className={hoveredRowIndex === rowIndex ? classes.contentWrapperHover : classes.contentWrapper}
                 ref={registerChild}
                 style={{
                     ...style,
                     padding: "6px 8px",
                     whiteSpace: 'nowrap',
                     borderBottom: "1px solid #ddd",
                     "&:hover": {
                         backgroundColor: "#ddd"
                     }
                 }}>
                <div style={{
                    padding: "6px 8px",
                }}>{columnDefinition.getDisplayValue(children)}</div>
            </div>
        )}

    </CellMeasurer>;
}
TableCellVirtualized.propTypes = {
    key: PropTypes.any.isRequired,
    parent: PropTypes.any.isRequired,
    style: PropTypes.any.isRequired,
    rowIndex: PropTypes.number.isRequired,
    columnIndex: PropTypes.number.isRequired,
    cellMeasurerCache: PropTypes.any.isRequired,
    children: PropTypes.node,
    serverColumn: PropTypes.any.isRequired,
    handleCellClick: PropTypes.func.isRequired,
    hoveredRowIndex: PropTypes.number.isRequired,
}