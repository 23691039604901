import React from 'react';
import PropTypes from 'prop-types';

import {DateTimePicker} from '@mui/x-date-pickers';

import {input, meta} from '@data-driven-forms/common/prop-types-templates';
import {useFieldApi} from '@data-driven-forms/react-form-renderer';
import {validationError} from "@data-driven-forms/mui-component-mapper";
import moment from "moment";
import {CustomTextField} from "./CustomTextField";
import {FormLabel} from "@mui/material";

export default function DateTimePickerCustom(props: any) {
    const {
              input,
              isReadOnly,
              isDisabled,
              placeholder,
              isRequired,
              label,
              helperText,
              description,
              validateOnMount,
              meta,
              DatePickerProps,
          }       = useFieldApi(props);
    const invalid = validationError(meta, validateOnMount);
    const styles = {margin: "22px 0 5px 0"};

    const isNotEditable = meta.dirty === false && input.value && props.editable

    return (isReadOnly || isNotEditable) ?
        <div>
            <FormLabel>{props.label}</FormLabel>
            <div>{input.value ? moment(input.value).format("yy/MM/DD hh:mm:ss") : null}</div>
        </div> : <DateTimePicker

            renderInput={(props: any) => <CustomTextField
                {...props}
                fullWidth
                margin="normal"
                label={label}
                helperText={invalid || ((meta.touched || validateOnMount) && meta.warning) || helperText || description}
                placeholder={placeholder}
                required={isRequired}
                error={!!invalid}
            />}
            label={label}
            sx={styles}
            disabled={isDisabled || isReadOnly || isNotEditable}
            readOnly={isReadOnly || isNotEditable}
            {...input}
            value={moment(input.value) || null}
            {...DatePickerProps}
        />;
}

DateTimePickerCustom.propTypes = {
    input,
    meta,
    isReadOnly        : PropTypes.bool,
    isDisabled        : PropTypes.bool,
    placeholder       : PropTypes.node,
    isRequired        : PropTypes.bool,
    label             : PropTypes.node,
    helperText        : PropTypes.node,
    validateOnMount   : PropTypes.bool,
    description       : PropTypes.node,
    FormFieldGridProps: PropTypes.object,
    DatePickerProps   : PropTypes.object,
};

DateTimePickerCustom.defaultProps = {
    FormFieldGridProps: {},
    DatePickerProps   : {},
};
