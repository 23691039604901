import Tooltip from "@mui/material/Tooltip";
import * as Icon from "react-feather";
import {sidebarTxColor} from "../../styles/getMuiTheme";
import PropTypes from "prop-types";
import React from "react";

export function SidebarCollapseButton(props: any) {
    return <div
        style={{
            display       : "flex",
            justifyContent: "center",
            margin        : "10px 0"
        }}
    >
        <Tooltip
            title={'Collapse'}
            placement="right"
            arrow={true}
            PopperProps={{
                modifiers: [
                    {
                        name   : "offset",
                        options: {
                            offset: [0, -5],
                        },
                    },
                ],
            }}
        >
            {props.isCollapsed ? (

                <Icon.AlignJustify
                    id={"collapse_sidebar_button"}
                    onClick={props.toggleSidebar}
                    style={{
                        color       : sidebarTxColor.hexa(),
                        cursor      : "pointer",
                        marginBottom: "10px",
                    }}
                />
            ) : (
                <Icon.AlignJustify
                    id={"collapse_sidebar_button"}
                    onClick={props.toggleSidebar}
                    style={{color: sidebarTxColor.hexa(), cursor: "pointer"}}
                />
            )}
        </Tooltip>
    </div>
}

SidebarCollapseButton.propTypes = {
    isCollapsed  : PropTypes.bool.isRequired,
    toggleSidebar: PropTypes.func.isRequired
}