import React, {useContext} from "react";
import {MyDataContext} from "../../ToolsComponent";
import {makeStyles} from "@mui/styles";
import {Button, Theme} from "@mui/material";
import {
    sidebarBackgroundHoverColor,
    sidebarBgColor,
    sidebarTextHoverColor,
    sidebarTxColor,
    whiteColor
} from "../../styles/getMuiTheme";
import {IconToolTip} from "../login/IconToolTip";
import {MoreVertical, User} from "react-feather";
import {SignOutButton} from "../login/SignOutButton";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

export function CurrentUser(props: any) {

    const [state, setState] = useContext(MyDataContext);

    const useStyles = makeStyles(
        // @ts-ignore
        (theme: Theme) => {
            return ({
                userButton  : {
                    height   : "40px",
                    width    : "40px",
                    padding  : "8px",
                    "&:hover": {
                        borderRadius   : "50%",
                        backgroundColor:
                            sidebarBackgroundHoverColor.hexa() + " !important",
                        color          : sidebarTextHoverColor.hexa() + " !important",
                    },
                },
                loggedInUser: {
                    display        : "flex",
                    alignItems     : "center",
                    fontSize       : "14px",
                    justifyContent : "space-between",
                    color          : sidebarTxColor.hexa(),
                    backgroundColor: sidebarBgColor.darken(0.2).hexa(),
                    margin         : "5px 10px",
                    padding        : "0px 10px",
                    borderRadius   : "6px"
                },
                logoutButton: {
                    cursor   : "pointer",
                    "&:hover": {
                        color     : sidebarBgColor.hexa(),
                        fontWeight: "bold"
                    },
                },
                plainButton : {
                    cursor   : "pointer",
                    color    : sidebarTxColor.hexa(),
                    width    : "100%",
                    "&:hover": {
                        color     : whiteColor.hexa(),
                        fontWeight: "bold"
                    }
                }

            });
        }
    );

    const classes = useStyles() as any;
    const text    = "## Information\n" +
        "---\n" +
        "Copyright © 2023-2024\n" +
        "Lion Hills, LLC.\n" +
        "All Rights Reserved.\n" +
        "### Privacy Policy\n" +
        "TBD"

    return <div className={props.isCollapsed ? classes.plainButton : classes.loggedInUser}>
        <div style={{display: "flex", alignItems: "center"}}>
            {props.isCollapsed ? (
                <IconToolTip
                    arrow={true}
                    placement="right"
                    PopperProps={{modifiers: [{name: "offset", options: {offset: [0, -5],},},],}}
                    iconButton={<User color={sidebarTxColor.hexa()} className={classes.userButton}/>}
                    title={state.account.name}
                >
                    <span className={classes.logoutButton} onClick={() => state.handleLogout()}>
                      <SignOutButton/>
                      <span>Sign Out</span>
                    </span>
                    <ReactMarkdown remarkPlugins={[gfm]}>{text}</ReactMarkdown>
                    <div>
                        <Button onClick={state.handleToolStateClear}>
                            Clear Cache
                        </Button>
                    </div>
                </IconToolTip>
            ) : (
                <User style={{marginRight: "5px"}}/>
            )}
            <div style={{flexDirection: "column"}}>
                {props.isCollapsed ? null : <div>{state.account.name}</div>}
                <div style={{
                    maxWidth    : state.sideBarWidth - 100,
                    fontWeight  : "normal",
                    fontSize    : "12px",
                    overflow    : "hidden",
                    textOverflow: "ellipsis"
                }}>
                    {/*({state.account.username})*/}
                </div>
            </div>
        </div>
        {props.isCollapsed ? null :
            <IconToolTip iconButton={<MoreVertical/>} title={"More"}>
                                <span className={classes.logoutButton}
                                      onClick={() => state.handleLogout()}><SignOutButton/>
                                    <span>Sign Out</span>
                                </span>
                <ReactMarkdown remarkPlugins={[gfm]}>
                    {text}
                </ReactMarkdown>
                <div><Button onClick={state.handleToolStateClear}>Clear Cache</Button></div>
            </IconToolTip>
        }
    </div>;
}