import {ToolCategory, ToolStack} from "../../classes";
import * as Icon from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import Popover from "@mui/material/Popover";
import {List, Theme} from "@mui/material";
import {SidebarToolGroup} from "./SidebarToolGroup";
import PropTypes from "prop-types";
import React from "react";
import {makeStyles} from "@mui/styles";
import {sidebarBackgroundHoverColor, sidebarTextHoverColor, sidebarTxColor,} from "../../styles/getMuiTheme";

export function CollapsedSidebarCategory({
                                             category,
                                             handleClick,
                                             expanded,
                                             sideBarWidth,
                                             anchorEl,
                                             handleClose,
                                             toolStacks,
                                             handleSidebarToolClick
                                         }: {
    category: ToolCategory,
    handleClick: any,
    expanded: any,
    sideBarWidth: number,
    anchorEl: any,
    handleClose: any,
    toolStacks: ToolStack[],
    handleSidebarToolClick: any
}) {
    // @ts-ignore
    const IconTag = Icon[category.iconName ? category.iconName : 'HelpCircle'] as any || Icon['HelpCircle'];

    const categoryName = category.name;

    const useStyles = makeStyles(
        // @ts-ignore
        (theme: Theme) => {
            return ({
                buttons      : {
                    cursor        : "pointer",
                    color         : sidebarTxColor.hexa(),
                    display       : "flex",
                    justifyContent: "center",
                    justifySelf   : "center",
                    height        : "40px",
                    width         : "59px",
                    padding       : "8px 18px",
                    "&:hover"     : {
                        backgroundColor:
                            sidebarBackgroundHoverColor.hexa() + " !important",
                        color          : sidebarTextHoverColor.hexa() + " !important",
                    },
                },
                buttonsActive: {
                    cursor         : "pointer",
                    display        : "flex",
                    justifyContent : "center",
                    justifySelf    : "center",
                    height         : "40px",
                    width          : "59px",
                    padding        : "8px 18px",
                    backgroundColor:
                        sidebarBackgroundHoverColor.hexa() + " !important",
                    color          : sidebarTextHoverColor.hexa() + " !important",
                },
                categoryName : {
                    marginLeft  : "12px",
                    textOverflow: "ellipsis",
                    whiteSpace  : "nowrap",
                    display     : "flex"
                }
            });
        }
    );

    const classes = useStyles()
    return <div key={categoryName}>
        <div onClick={(e) => handleClick(e, categoryName)}>
            <Tooltip
                title={categoryName}
                placement="right"
                arrow={true}
                PopperProps={{modifiers: [{name: "offset", options: {offset: [0, -5],},},],}}>
                <IconTag className={expanded !== categoryName ? classes.buttons : classes.buttonsActive}/>
            </Tooltip>
            {sideBarWidth > 200 && <div className={classes.categoryName}>{categoryName}</div>}
        </div>
        <Popover
            id={expanded === categoryName ? "simple-popover" : undefined}
            open={expanded === categoryName}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{vertical: "bottom", horizontal: "right",}}
            transformOrigin={{vertical: "top", horizontal: "left",}}>
            <List>
                {
                    toolStacks
                        .filter((toolStack: ToolStack) => toolStack.toolCategoryName === categoryName)
                        .map((toolStack: ToolStack, toolIndex: number) =>
                            <SidebarToolGroup classes={classes}
                                              handleSidebarToolClick={() => handleSidebarToolClick(toolStack)}
                                              toolIndex={toolIndex}
                                              componentKey={toolStack.key}
                                              selected={toolStack.selected}
                                              title={toolStack.title}
                                              toolCategoryName={toolStack.toolCategoryName}
                                              handleClose={handleClose}/>)
                }
            </List>
        </Popover>
    </div>;
}

CollapsedSidebarCategory.propTypes = {
    category              : PropTypes.any.isRequired,
    handleClick           : PropTypes.func.isRequired,
    expanded              : PropTypes.any.isRequired,
    sideBarWidth          : PropTypes.number.isRequired,
    anchorEl              : PropTypes.any.isRequired,
    handleClose           : PropTypes.func.isRequired,
    toolStacks            : PropTypes.any.isRequired,
    handleSidebarToolClick: PropTypes.func.isRequired
}