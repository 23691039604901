import React, {useEffect} from "react";
import cloneDeep from "lodash.clonedeep"
import {getColumnComponent, getMuiTableColumns, getServerColumnsFromToolMetadata} from "./ColumnsUtil"
import getMuiTheme from "./styles/getMuiTheme";
import Sidebar from "./components/sidebar/SideBar";
import _, {startCase} from "lodash";
import DrillDownModal from "./components/drilldown/DrillDownModal";
import {getChartData, processDualYStackedBarChart} from "./components/charts/ChartUtils";
import {
    Customer,
    Drilldown,
    Filter,
    FilterCriterion,
    MuiTableColumn,
    ServerColumn,
    Sort,
    StackFrame,
    StackState,
    Tool,
    ToolGroup,
    ToolStack,
    ToolState,
    ToolTab
} from "./classes";
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import ToolBreadcrumbs from "./components/breadcrumbs/ToolBreadcrumbs";
import ToolGridComponent from "./components/grid/ToolGridComponent";
import BaseToolComponent from "./components/BaseToolComponent";
import {
    additionalTools,
    createStackFrame,
    createToolStacks,
    findToolGroupByKey,
    getOrCreateDrilldownStackFrame,
    getStackState,
    initializeToolFilterList,
    isStackStateEqual
} from "./util/ToolUtils";
import {doDelete, doGet, doPost, doToolMetadataRequest} from "./util/RestUtil";
import {
    clearCache,
    clearCacheAndReload,
    getCurrentCustomer,
    getCustomers,
    getDbStatus,
    getDescriptions,
    getDrilldowns,
    getForms,
    getNavigation,
    getNewToolStackStates,
    getToolStackStates,
    setLocalStorageItem
} from "./util/LocalStorageUtil";
import FormComponent from "./components/forms/FormComponent";
import BasicTabs from "./components/tabs/BasicTabs";
import {useMsal} from "@azure/msal-react";
import ls from "local-storage";
import LoadingModal from "./components/LoadingModal";
import getAnyByUUID from "./util/UUIDUtil";
import {
    CUSTOMERS_URL,
    NAV_URL,
    QUEUE_ACTIONS_URL,
    QUEUE_LOCATIONS_URL,
    TABLE_ENUM_URL,
    TOOL_STATE_URL,
    TOOLS_URL
} from "./util/RestRoutes";
import ErrorComponent from "./components/ErrorComponent";
import DbStatusModal from "./components/DbStatusModal";

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }

    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

export const MyDataContext = React.createContext(undefined as any);

export let NAVIGATION   = null as any
export let DESCRIPTIONS = null as any
export let DRILLDOWNS   = null as any
export let FORMS        = null as any
export let CUSTOMERS    = null as Customer[] | null

export default function ToolsComponent() {

    const {accounts}        = useMsal();
    const {instance}        = useMsal();
    // @ts-ignore
    const [state, setState] = React.useState({
        dbStatus                        : null as any,
        initializing                    : false,
        clearingCache                   : null as string | null,
        error                           : false,
        errorData                       : null as any,
        account                         : accounts[0] as any,
        detailActions                   : null as any,
        drilldownHeaderText             : null as any,
        toolComponent                   : additionalTools[0] as {
            categoryName: string,
            groupName: string,
            component: any
        } | null,
        toolStacks                      : [] as ToolStack[],
        currentStack                    : null as ToolStack | null,
        currentFrame                    : null as StackFrame | null,
        mermaidModalOpen                : false,        // TODO: deprecate
        mermaidModalData                : null as any,// TODO: deprecate
        currentCustomer                 : null as Customer | null,
        sideBarWidth                    : 275,
        handleMermaidWidthChange          : (tool: Tool, event: any) => updateMermaidWidth(event.target.value, tool),
        handleMermaidRefreshIntervalChange: (tool: Tool, event: any) => updateToolRefreshInterval(event, tool),
        handleTablePageChange             : (tool: Tool, currentPage: number) => updateTablePagination(tool, currentPage),
        handleTableNumberOfRowsChange     : (tool: Tool, numberOfRows: number) => updateTableDisplayedRows(tool, numberOfRows),
        handleTableRowClick               : async (tool: Tool, rowData: string[], rowMeta: {
            dataIndex: number,
            rowIndex: number
        }, drilldownForms: any) => doTableDrilldown(tool, rowMeta.rowIndex, state.currentFrame!, drilldownForms),
        handleMermaidNodeClick            : async (queueName: any) => await loadMermaidModal(queueName),
        handleTableSortChange           : (tool: Tool, name: string, direction: string) => updateTableSort(tool, name, direction),
        handleToolFilterChange          : (tool: Tool, filterList: any[], skipFilterListCheck: boolean = false) => updateToolFilters(tool, skipFilterListCheck, filterList),
        handleChartFilterChange         : (tool: Tool, filterList: any[], skipFilterListCheck: boolean = false, serverSideFiltering: boolean = false) => updateChartFilter(tool, skipFilterListCheck, filterList, serverSideFiltering),
        handleChartDataClick              : (tool: Tool, chartSelection: {
            row: any;
            column: any;
        }[], filteredToolData: any) => doChartDrilldown(tool, chartSelection, filteredToolData, state.currentFrame!),
        handleChartRefresh                : () => save(),
        handleStackFrameReload            : (stackFrame: StackFrame, _event: any) => doLoadFrame(stackFrame),
        handleTableRowExpansionChange     : (_event: any) => console.log("Table row expansion not implemented"),
        handleTableVisibleColumnsChange   : (tool: Tool, changedColumn: string, action: string) => updateVisibleColumns(tool, changedColumn, action),
        handleTableColumnOrderChange      : (tool: Tool, newColumnOrder: [], _columnIndex: number, _newPosition: number) => updateColumnOrder(tool, newColumnOrder),
        handleLocalStorageClear           : () => clearCacheAndReload(),
        handleToolStateClear              : deleteToolStates,
        handleToolRefresh                 : (tool: Tool) => refreshTool(tool),
        handleSidebarToolClick            : (toolStack: ToolStack) => selectToolStack(toolStack),
        handleSidebarAdditionalToolClick: (tool: { categoryName: string, groupName: string, component: any }) => {
            state.toolComponent = tool;
            save()
        },
        handleDrilldownClose              : (_event: any) => closeMermaidDrilldown(),
        handleSidebarResize               : (event: number) => updateSidebarWidth(event),
        handleChartTypeChange             : (tool: Tool, event: any) => updateChartType(tool, event.target.value),
        handleGridLayoutChange            : (groupOrTab: ToolGroup | ToolTab, layout: any[]) => updateGridLayout(groupOrTab, layout),
        handleCustomerChange              : (event: any) => changeCustomer(event.target.value),
        handleBreadcrumbClick             : (frame: StackFrame) => selectAndLoadFrame(frame, false),
        handleFormButtonClick           : (formDbName: string, detailID = null, idColumnName = null) => openForm(formDbName, detailID, idColumnName),
        handleFullscreenClick             : (toolUUID: any) => loadToolStackFrame(toolUUID, state.currentStack!, state.currentFrame!),
        handleFreeformToolLoad          : (toolUUID: any, error: boolean = false) => stopToolLoading(toolUUID, error),
        handleFormClose                   : closeForm,
        handleTabSelect                   : (groupOrTab: ToolGroup | ToolTab, selectedTabIndex: number) => updateSelectedTab(groupOrTab, selectedTabIndex),
        handleFormSubmit                  : () => doLoadFrame(state.currentFrame!, true),
        handleLogout                      : () => doLogout(instance),
        getTextSearch                   : async (toolUUID: string, columnName: string, tableName: string, searchStr: string) => await getTextSearch(toolUUID, columnName, tableName, searchStr)
    })

    const [stateSaved, setStateSaved] = React.useState(true)
    let refreshingStates              = false

    const [formModalOpen, setFormModalOpen]                 = React.useState(false)
    const [formModalDbName, setFormModalDbName]             = React.useState(null as any)
    const [formModalEntityID, setFormModalEntityID]         = React.useState(null as any)
    const [formModalIdColumnName, setFormModalIdColumnName] = React.useState(null as any)

    const save = () => {
        setLocalStorageItem('newToolStackStates', state.toolStacks.map(getStackState), 86400)
        setState(cloneDeep({...state}));
    };

    function stopToolLoading(uuid: any, error: boolean = false) {
        let tool         = getByUUID(uuid) as Tool
        tool.isLoading   = false;
        tool.initialized = true;
        tool.error       = error;
        save()
    }

    function getByUUID(uuid: any): ToolStack | StackFrame | ToolGroup | ToolTab | Tool {
        return getAnyByUUID(uuid, state.toolStacks)
    }

    function selectAndLoadFrame(frame: StackFrame, reload: boolean = true) {
        if (!frame) {
            return;
        }

        let stackFrame = getByUUID(frame.uuid) as StackFrame
        selectStackFrame(stackFrame)
        save()
        doLoadFrame(stackFrame, reload)
    }

    function selectStackFrame(stackFrame: StackFrame) {
        stackFrame.selected = true;
        state.currentFrame  = stackFrame
        state.currentStack!.stackFrames.filter((f: StackFrame) => f.uuid !== stackFrame.uuid).forEach((f: StackFrame) => f.selected = false)
    }

    async function doLoadFrame(stackFrame: StackFrame, reload: boolean = true) {
        if (stackFrame.type === "GROUP") {
            await doLoadToolGroup(stackFrame.toolOrGroup as ToolGroup, reload)
        } else {
            let tool = getByUUID((stackFrame.toolOrGroup as Tool).uuid) as Tool
            if (reload || tool.toolData === null) {
                tool.isLoading = true
            }
            await doLoadTool(stackFrame.toolOrGroup as Tool, reload)
        }
    }

    function doLogout(instance: any) {
        clearCache()
        instance.logoutRedirect().catch((e: any) => console.error(e));
    }

    function doLoadTabs(parent: ToolGroup | ToolTab, reload: boolean) {
        const tabs = parent.tabs;

        if (!tabs) {
            return
        }

        let selectedTabs = tabs.filter((tab: ToolTab) => tab.selected);

        if (selectedTabs && selectedTabs.length === 0) {
            selectedTabs            = [tabs[0]]
            parent.selectedTabIndex = 0
        }


        selectedTabs.forEach((tab: ToolTab) => {

            doLoadTabs(tab, reload)

            tab.parsedTools?.forEach(t => {
                let tool = getByUUID(t.uuid) as Tool
                if (reload || tool.toolData === null) {
                    tool.isLoading = true
                }
            })

            save()

            tab.parsedTools?.forEach((tool: Tool) => doLoadTool(tool, reload))
        })
    }

    async function doLoadToolGroup(toolGroup: ToolGroup, reload: boolean = true) {
        if (toolGroup.tabs && toolGroup.tabs.length > 0) {
            doLoadTabs(toolGroup, reload);
        } else {
            toolGroup.parsedTools?.forEach(t => {
                let tool = getByUUID(t.uuid) as Tool
                if (reload || tool.toolData === null) {
                    tool.isLoading = true
                }
            })
            save()
            toolGroup.parsedTools?.forEach(t => doLoadTool(t, reload))
        }
    }

    async function processTableData(tool: Tool, config: any) {

        tool = getByUUID(tool.uuid) as Tool
        try {

            let [toolData, resp] = await doToolRequest(tool, config);

            if (toolData.data) {
                tool.toolData = toolData.data
            } else {
                tool.toolData = toolData
            }


            if (tool.displayTextColumnName && tool.toolData.length > 0 && tool.toolData[0][tool.displayTextColumnName]) {
                tool.customTitle = tool.toolData[0][tool.displayTextColumnName]
            }

            tool.filterList    = initializeToolFilterList(tool);
            tool.mappedColumns = getMuiTableColumns(tool, tool.toolData)

            // This change is necessary to avoid the count query blocking table data from showing up
            tool.isLoading = false
            save()

            let [countData] = await doToolRequest(tool, {
                ...config,
                params: {...config.params, count: 1}
            });

            tool.pagingInfo = countData;
        } catch (e: any) {
            if (e.message !== 'canceled') {
                tool.error    = true
                tool.toolData = e
            }
        }


    }

    async function processChartData(tool: Tool, config: any) {
        tool = getByUUID(tool.uuid) as Tool
        try {

            let [toolData] = await doToolRequest(tool, config);

            if (toolData.data) {
                tool.toolData = getChartData(toolData.data)
            } else {
                tool.toolData = getChartData(toolData)
            }

            if (toolData.options) {
                tool.options = {chartOptions: toolData.options, url: null}
            }

            if (tool.options?.chartOptions?.chartType) {
                tool.chartType = tool.options?.chartOptions?.chartType
            }

            if (tool.options?.chartOptions?.filters) {
                tool.columns = tool.options?.chartOptions?.filters
            }

            tool.filterList = initializeToolFilterList(tool);
            tool.mappedColumns = getMuiTableColumns(tool, toolData)

            if (tool.chartType === 'DualYStackedBarChart') {
                tool.toolData = processDualYStackedBarChart(tool.toolData);
            }

        } catch (e: any) {
            if (e.message !== 'canceled') {
                tool.error    = true
                tool.toolData = e
            }
        }

    }

    async function processJsonData(tool: Tool, config: any) {
        tool = getByUUID(tool.uuid) as Tool
        try {

            let [toolData] = await doToolRequest(tool, config);

            if (toolData.data) {
                tool.toolData = JSON.parse(toolData.data[0].jsonResult)
            } else {
                let result = toolData[0].jsonResult
                if (!result) {
                    result = toolData[0].json
                }
                if (result) {
                    const toolData1 = JSON.parse(result);
                    if (Array.isArray(toolData1)) {
                        tool.toolData = {filler: "lame", ...toolData1[0]}
                    } else {
                        tool.toolData = toolData1
                    }
                }
            }

            if (tool.displayTextColumnName && tool.toolData && tool.toolData[tool.displayTextColumnName]) {
                tool.customTitle = tool.toolData[tool.displayTextColumnName]
            }
        } catch (e: any) {
            if (e.message !== 'canceled') {
                tool.error    = true
                tool.toolData = e
            }
        }

    }

    async function processPDFData(tool: Tool, config: any) {
        tool = getByUUID(tool.uuid) as Tool
        try {
            let [toolData, response] = await doToolRequest(tool, config);
            tool.toolData            = atob(toolData);
            tool.customTitle         = response.headers['pdf-header'];
        } catch (e: any) {
            if (e.message !== 'canceled') {
                tool.error    = true
                tool.toolData = e
            }
        }
    }

    async function processMermaidData(tool: Tool, config: any) {
        tool = getByUUID(tool.uuid) as Tool
        try {
            let [, response] = await doToolRequest(tool, config);
            tool.toolData    = response.data
        } catch (e: any) {
            if (e.message !== 'canceled') {
                tool.error    = true
                tool.toolData = e
            }
        }

    }

    async function processFreeformData(tool: Tool, config: any) {
        // if (!tool.datasources) {
        //     return
        // }
        //
        // let toolData: any = {}
        //
        // tool.datasources.forEach((ds: any) => {
        //     const sortstr      = ds.source.split('|').find((s: string) => s.startsWith("SORTS="))
        //     const defaultSorts = parseDefaultSorts(sortstr)
        //     const source       = ds.source.split('|')[0]
        //     doPost(`${TOOLS_URL}/${source}PATH_UNIQUE_KEY=${ds.name}###`, {
        //         ...tool.params,
        //         sorts: [...tool.params.sorts, ...defaultSorts]
        //     }, config, state.currentCustomer?.id, 1).then((resp: any) => {
        //         let name       = ds.name
        //         toolData[name] = resp.data
        //         tool           = getByUUID(tool.uuid) as Tool
        //         tool.toolData  = toolData
        //         save()
        //     });
        // })

        // const responses = await Promise.all(requests) as any;
        //
        // for (let i = 0; i < tool.datasources.length; i++) {
        //     let ds         = tool.datasources[i]
        //     let name       = ds.name
        //     let source     = ds.source
        //     let data       = responses[i]
        //     toolData[name] = data.data
        // }
        // tool          = getByUUID(tool.uuid) as Tool
        // tool.toolData = toolData
    }


    async function doLoadTool(tool: Tool, reload: boolean = true, clearPagination: boolean = true) {


        console.log("Start load tool: " + tool.title)
        setStateSaved(false)
        save()

        if (!reload && tool.toolData !== null) {
            return;
        }

        if (tool.toolType === 'FREEFORM') {
            tool.isLoading = true
            save()
            return;
        }

        try {
            tool.isLoading = true
            save()

            let config: any = {params: {count: 0}}

            if (tool.searchStr) {
                config.params['searchStr'] = tool.searchStr
            }

            if (tool.detailID) {
                config.params['detailID'] = tool.detailID
            }

            if ((tool.toolType !== 'FREEFORM' && !tool.entityType) || (tool.toolType === 'TABLE' && (!tool.columns || tool.columns.length === 0))) {
                let [metadata] = await doToolMetadataRequest(tool, config, state.currentCustomer?.id)
                tool           = getByUUID(tool.uuid) as Tool

                if (!tool.url) {
                    tool.entityType = metadata.type

                    if (tool.params) {
                        tool.params.entityType = tool.entityType
                    }

                    if (tool.toolType === 'TABLE') {
                        tool.columns = getServerColumnsFromToolMetadata(metadata, tool.columnStates)
                    }

                    save()
                }

            }

            setTimeout(async () => {
                prepareToolForLoading(tool, config, clearPagination);
                save()


                switch (tool.toolType) {
                    case 'TABLE':
                        await processTableData(tool, config);
                        break;
                    case 'CHART':
                        await processChartData(tool, config);
                        break;
                    case 'JSON':
                        await processJsonData(tool, config);
                        break;
                    case 'PDF':
                        await processPDFData(tool, config);
                        break;
                    case 'MERMAID':
                        await processMermaidData(tool, config);
                        break;
                    case 'FREEFORM':
                        await processFreeformData(tool, config);
                        break;

                }
                save()
                tool           = getByUUID(tool.uuid) as Tool
                tool.isLoading = false;
                tool.initialized = true;
                save()
                console.log("End load tool: " + tool.title)
            }, 0)


        } catch (e: any) {
            if (e.message !== 'canceled') {
                tool.error    = true
                tool.toolData = e
            }
            tool.isLoading = false
            tool.initialized = true;
            save()
        }

    }

    function prepareToolForLoading(tool: Tool, config: any, clearPagination: boolean) {

        tool             = getByUUID(tool.uuid) as Tool
        tool.error       = false
        tool.lastUpdated = new Date()
        tool.toolData    = null;

        if (!tool.title) {
            tool.title = startCase(tool.dbName)
            if (tool.title.endsWith(" View")) {
                tool.title = tool.title.substring(0, tool.title.indexOf(" View"))
            }
        }

        if (tool.pagingInfo && clearPagination) {
            tool.pagingInfo.recordCount = null
            tool.pagingInfo.totalPages  = null
        }

        if (!tool.params) {
            tool.params = {
                filters     : [],
                sorts       : tool.defaultSorts || [],
                paging      : {page: 0, rows: 20},
                entityType  : tool.entityType,
                toolType    : tool.toolType,
                idColumnName: tool.idColumnName
            }
        }

        if ((!tool.params.sorts || tool.params.sorts.length === 0) && tool.defaultSorts) {
            tool.params.sorts = tool.defaultSorts
        }

        if (!tool.filterList) {
            tool.filterList = initializeToolFilterList(tool);
        }

        if (tool.idColumnName) {
            const filter = new Filter(tool.idColumnName)
            filter.criteria.push(new FilterCriterion("eq", tool.detailID))
            tool.params = {
                ...tool.params,
                filters     : [...tool.params.filters.filter(f => f.attr !== tool.idColumnName), filter],
                idColumnName: tool.idColumnName
            }
        }

    }

    async function doToolRequest(tool: Tool, config: any) {
        try {
            let resp

            if (tool.url) {
                resp = await doGet(`${tool.url.split("=")[1]}`, config, state.currentCustomer?.id) as any;
            } else {
                let finalParams = {
                    ...tool.params,
                    filters: tool.toolType === "CHART" && !tool.serverSideFiltering ? [...tool.params.filters.filter(f => f.attr === tool.idColumnName)] : tool.params.filters,
                }
                resp            = await doPost(`${TOOLS_URL}/${tool.dbName}`, finalParams, config, state.currentCustomer?.id) as any
            }

            return [resp.data, resp];
        } catch (e) {
            throw e
        }
    }

    function updateMermaidWidth(mermaidWidth: number, tool: Tool) {
        console.log("handleMermaidWidthChange", mermaidWidth)
        tool              = getByUUID(tool.uuid) as Tool
        tool.mermaidWidth = mermaidWidth;
        save()
    }


    function updateToolRefreshInterval(event: any, tool: Tool) {
        const refreshInterval = event.target.value;
        console.log("handleMermaidRefreshIntervalChange", refreshInterval)
        tool                 = getByUUID(tool.uuid) as Tool
        tool.refreshInterval = refreshInterval
        save()
    }

    function updateTablePagination(tool: Tool, currentPage: number) {
        if (!tool || _.isEqual(tool.params?.paging?.page, currentPage)) {
            return;
        }

        tool                    = getByUUID(tool.uuid) as Tool
        tool.params.paging.page = currentPage

        save()
        doLoadTool(tool, true, false).then(_r => null)
    }

    function updateTableDisplayedRows(tool: Tool, numberOfRows: number) {
        if (!tool || _.isEqual(tool.params?.paging?.rows, numberOfRows)) {
            return;
        }

        tool                    = getByUUID(tool.uuid) as Tool
        tool.params.paging.page = 0
        tool.params.paging.rows = numberOfRows;

        save()
        doLoadTool(tool).then(_r => null)
    }

    function updateGridLayout(groupOrTab: ToolGroup | ToolTab, gridLayout: any[]) {
        groupOrTab                  = getByUUID(groupOrTab.uuid) as ToolGroup | ToolTab
        groupOrTab.parsedGridLayout = gridLayout
        groupOrTab.lastUpdated      = new Date()
        setStateSaved(false)
        save()
    }

    function updateToolFilters(tool: Tool, skipFilterListCheck: boolean, filterList: any[]) {
        if (!tool || (!skipFilterListCheck && _.isEqual(tool.filterList, filterList))) {
            return;
        }

        tool = getByUUID(tool.uuid) as Tool

        if (!tool || (!skipFilterListCheck && _.isEqual(tool.filterList, filterList))) {
            return;
        }

        if (!tool.params) {
            tool.params = {
                entityType  : null,
                filters     : [],
                idColumnName: null,
                paging      : {page: 0, rows: 20},
                sorts       : [],
                toolType    : null
            }
        }

        tool.params.filters     = createToolFilters(filterList, tool)
        tool.params.paging.page = 0
        tool.filterList         = filterList;
        tool.mappedColumns      = getMuiTableColumns(tool, tool.toolData);

        save()
        doLoadTool(tool, true)

    }

    function createToolFilters(filterList: any[], tool: Tool) {
        let filters = [] as Filter[]

        for (let i = 0; i < filterList.length; i++) {
            let columnFilterArray = filterList[i];
            if (columnFilterArray.length > 0) {
                let column = tool.mappedColumns?.[i] as MuiTableColumn
                if (column !== null && column !== undefined) {
                    let serverColumn = tool.columns?.find(c => c.attr === column.name) as ServerColumn
                    let filter       = getColumnComponent(serverColumn).getFilter(serverColumn, columnFilterArray) as Filter;
                    if (filter) filters.push(filter)
                }
            }
        }
        return filters;
    }

    function updateVisibleColumns(tool: Tool, changedColumn: string, action: string) {
        tool             = getByUUID(tool.uuid) as Tool
        let mappedColumn = tool.mappedColumns?.find(c => c.name === changedColumn);
        if (mappedColumn) {
            const displayColumn          = action === "add";
            mappedColumn.visible         = displayColumn
            mappedColumn.options.display = displayColumn ? 'true' : 'false'
        }

        save()
    }

    function updateColumnOrder(tool: Tool, newColumnOrder: []) {
        tool = getByUUID(tool.uuid) as Tool

        if (!tool.mappedColumns) {
            throw new Error("No columns for column order")
        }

        for (let i = 0; i < tool.mappedColumns.length; i++) {
            tool.mappedColumns[i].actualOrder = newColumnOrder[i]
        }

        save()
    }

    function updateTableSort(tool: Tool, name: string, direction: string) {
        if (!tool) {
            return;
        }

        tool = getByUUID(tool.uuid) as Tool

        let sorts         = [] as Sort[]
        let sortName      = name;
        let sortDirection = direction.toUpperCase();

        tool.sortOrder = {name, direction}

        if (sortDirection !== "NONE") {
            sorts.push({attr: sortName, direction: sortDirection})
        } else if (tool.defaultSorts) {
            sorts = tool.defaultSorts
        }

        tool.params = {...tool.params, sorts};

        save()
        doLoadTool(tool).then(_r => null)
    }

    function updateChartFilter(tool: Tool, skipFilterListCheck: boolean, filterList: any[], serverSideFiltering: boolean = false) {
        if (!tool || (!skipFilterListCheck && _.isEqual(tool.filterList, filterList))) {
            return;
        }

        tool                     = getByUUID(tool.uuid) as Tool
        tool.filterList          = filterList;
        tool.lastUpdated         = new Date()
        tool.serverSideFiltering = serverSideFiltering
        tool.params.filters      = createToolFilters(filterList, tool)

        setStateSaved(false)
        if (serverSideFiltering) {
            doLoadTool(tool, true)
        }
        save()
    }

    function updateSidebarWidth(event: number) {
        state.sideBarWidth = event;
        save()
    }

    function updateChartType(tool: Tool, chartType: any) {
        tool = getByUUID(tool.uuid) as Tool
        tool.chartType = chartType
        save()
    }

    async function getTextSearch(toolUUID: string, columnName: string, tableName: string, searchStr: string) {
        const tool     = getByUUID(toolUUID) as Tool
        const config   = {
            params: {
                columnName: columnName,
                tableName : tableName,
                searchStr : searchStr,
            }
        };
        const response = await doPost(TABLE_ENUM_URL, tool.params, config, state.currentCustomer?.id) as any
        return [...response.data]
    }

    function doChartDrilldown(tool: Tool, chartSelection: {
        row: any;
        column: any;
    }[], filteredToolData: any, stackFrame: StackFrame) {
        // if (!tool.drilldowns || tool.drilldowns.length === 0) {
        //     return
        // }
        // if (state.currentStack === null) {
        //     throw new Error("Well, how did I get here?")
        // }
        //
        // const drilldownToolID = tool.drilldowns[0].id
        //
        // if (chartSelection.length > 0 && chartSelection[0].row !== null && drilldownToolID) {
        //     const [searchStr, customTitle] = chartSelection
        //         .map((selection: { row: any; column: any; }) => {
        //             let viewName          = tool.dbViewName;
        //             let toolData          = filteredToolData;
        //             let row               = selection.row;
        //             let column            = selection.column;
        //             let toolDatum         = toolData[row + 1];
        //             let toolDatumElement  = toolData[0][column];
        //             let toolDatumElement1 = toolDatum[0];
        //             return [`${viewName} ${toolDatumElement1} ${toolDatumElement}`, `${toolDatumElement1} ${toolDatumElement}`];
        //         })[0];
        //
        //     let {newStackFrame, reload} = getOrCreateDrilldownStackFrame(
        //         state.currentStack,
        //         stackFrame,
        //         drilldownToolID,
        //         null,
        //         searchStr,
        //         customTitle
        //     );
        //
        //     selectAndLoadFrame(newStackFrame, reload)
        //
        // }

    }

    function doTableDrilldown(tool: Tool, rowIndex: number, stackFrame: StackFrame, drilldownForms: any) {

        //displayTextColumnName
        //idColumnName
        //group

        let drilldowns = DRILLDOWNS[tool.dbName]

        if (!drilldowns && (!drilldownForms || drilldownForms.length === 0)) {
            return
        }

        if (drilldownForms && drilldownForms.length > 0) {
            let drilldownDetailID = tool.toolData[rowIndex]['id']

            openForm(drilldownForms[0].view, drilldownDetailID, tool.idColumnName)
        } else {

            let {
                    drilldownDetailID,
                    drilldownGroup,
                    drilldownTitle,
                    idColumnName
                } = getDrilldownIDs(tool, rowIndex, drilldowns);

            if (!drilldownDetailID || !drilldownGroup) {
                throw new Error("Unable to create drilldown");
            }


            if (state.currentStack === null) {
                throw new Error("Well, how did I get here?")
            }


            let {newStackFrame, reload} = getOrCreateDrilldownStackFrame(
                state.currentStack,
                stackFrame,
                drilldownGroup,
                drilldownDetailID,
                idColumnName,
                state.currentCustomer?.id,
                null,
                drilldownTitle
            );

            selectAndLoadFrame(newStackFrame, reload)
        }


    }

    function openForm(formDbName: string, entityID: any = null, idColumnName: any = null) {
        setFormModalDbName(formDbName)
        setFormModalEntityID(entityID)
        setFormModalIdColumnName(idColumnName)
        setFormModalOpen(true)
    }


    function getDrilldownIDs(tool: Tool, rowIndex: number, drilldowns: Drilldown[]) {

        let drilldownDetailID = null
        let drilldownGroup    = null
        let drilldownTitle    = null
        let idColumnName      = null

        for (let i = 0; i < drilldowns.length; i++) {
            const drilldown = drilldowns[i];

            if (typeof drilldown.group === 'string') {
                drilldownGroup = findToolGroupByKey(drilldown.group)
            } else {
                drilldownGroup     = drilldown.group
                drilldownGroup.key = `drilldowns|${tool.dbName}|${drilldown.group.name}`
            }

            idColumnName = drilldown.idColumnName

            drilldownDetailID = tool.toolData[rowIndex][idColumnName]

            if (drilldown.displayTextColumnName) {
                drilldownTitle = tool.toolData[rowIndex][drilldown.displayTextColumnName]
            }

            if (drilldownDetailID) {
                break
            }
        }
        return {drilldownDetailID, drilldownGroup, drilldownTitle, idColumnName};
    }

    // function getFormDrilldownIDs(tool: Tool, rowIndex: number) {
    //
    //     const form     = tool.forms[0]
    //     const formID   = form.id
    //     const entityID = tool.toolData[rowIndex][form.idColumnName]
    //
    //     return {entityID, formID};
    // }


    function updateSelectedTab(groupOrTab: ToolGroup | ToolTab, selectedTabIndex: number) {

        if (!state.currentStack || !state.currentFrame) {
            throw Error("Well, how did I get here?")
        }

        let foundGroupOrTab = getByUUID(groupOrTab.uuid) as ToolGroup | ToolTab

        foundGroupOrTab.selectedTabIndex = selectedTabIndex

        let foundTab = groupOrTab.tabs ? groupOrTab.tabs[selectedTabIndex] : null

        if (!foundTab || !foundGroupOrTab.tabs) {
            throw Error("No tab found")
        }

        setStateSaved(false)

        for (let i = 0; i < foundGroupOrTab.tabs.length; i++) {
            const t: ToolTab = foundGroupOrTab.tabs[i];
            t.selected       = i === selectedTabIndex;
        }

        save()

        loadTabTools(foundTab, false)

    }

    function loadTabTools(tab: ToolTab, reload: boolean) {
        let foundTab = getByUUID(tab.uuid) as ToolTab

        if (!foundTab.selected) {
            return;
        }

        if (foundTab.tabs) {
            let selectedSubTab = foundTab.tabs.find((t: ToolTab) => t.selected)
            foundTab.tabs.forEach((t: ToolTab, i: number) => {
                if (!selectedSubTab && i === 0) {
                    t.selected = true
                }
                loadTabTools(t, reload);
            })
        } else {
            foundTab.parsedTools?.forEach(t => doLoadTool(t, reload))
        }
    }


    async function changeCustomer(customerName: string) {
        state.currentFrame    = null
        state.currentStack    = null
        state.toolStacks      = []
        state.currentFrame    = null
        const currentCustomer = CUSTOMERS?.find(c => c.name === customerName) || null;
        state.currentCustomer = currentCustomer

        setLocalStorageItem('currentCustomer', CUSTOMERS?.find(c => c.name === customerName) || null, 86400)

        save()

        // @ts-ignore
        ls.remove("toolStackStates")

        await fetchUIStates()

        state.toolStacks = createToolStacks(NAVIGATION, getToolStackStates(), currentCustomer?.id)

        save()

        selectToolStack(state.toolStacks.find((ts: ToolStack) => ts.selected) || state.toolStacks[0])

        // doLoadFrame(state.currentFrame!)
    }

    function loadToolStackFrame(toolUUID: any, toolStack: ToolStack, currentFrame: StackFrame) {
        if (!toolUUID) {
            return;
        }

        let selectedTool = getByUUID(toolUUID) as Tool

        const currentFrameIndex = toolStack.stackFrames.indexOf(currentFrame);
        let reload              = true
        let newStackFrame       = null
        if (toolStack.stackFrames.length >= currentFrameIndex + 1) {
            let existingStackFrame = toolStack.stackFrames[currentFrameIndex + 1];
            if (existingStackFrame && existingStackFrame.toolOrGroup.uuid === toolUUID) {
                newStackFrame = existingStackFrame
                reload        = false
            }
        }

        if (!newStackFrame) {
            newStackFrame         = createStackFrame(selectedTool, 'TOOL')
            toolStack.stackFrames = [...toolStack.stackFrames.slice(0, currentFrameIndex + 1), newStackFrame]
            save()
        }

        selectAndLoadFrame(newStackFrame, reload)
    }

    function closeForm() {
        setFormModalOpen(false)
        setFormModalDbName(null)
        setFormModalEntityID(null)
    }

    function selectToolStack(toolStack: ToolStack) {

        if (!toolStack) {
            state.error     = true
            state.errorData = {"message": "App Failed to Load"}
            save()
            throw new Error("Well, how did I get here?")
        }

        state.toolComponent = null
        save()

        setCurrentToolStack(toolStack.uuid, toolStack.title)

        save()

        if (!state.currentStack) {
            state.error     = true
            state.errorData = {"message": "App Failed to Load"}
            throw new Error("Well, how did I get here?")
        }

        selectStackFrame(state.currentStack.stackFrames.find((f: StackFrame) => f.selected) || state.currentStack.stackFrames[0])

        if (!state.currentFrame) {
            state.error     = true
            state.errorData = {"message": "App Failed to Load"}
            throw new Error("Well, how did I get here?")
        }

        save() // TODO: is this necessary?

        doLoadFrame(state.currentFrame);

    }


    function setCurrentToolStack(stackUUID: any, stackTitle: string) {

        if (state.currentStack && state.currentStack?.uuid === stackUUID) {
            return;
        }

        state.currentStack = state.toolStacks.find((s: ToolStack) => s.uuid === stackUUID) || null

        if (!state.currentStack) throw new Error("Cannot find toolstack " + stackTitle)

        state.currentStack.selected = true

        state.toolStacks.filter((s: ToolStack) => s.selected && s.uuid !== stackUUID).forEach((s: ToolStack) => {
            s.selected    = false;
            s.lastUpdated = new Date()
        })
        state.toolStacks.filter((s: ToolStack) => s.selected && s.uuid === stackUUID).forEach((s: ToolStack) => {
            s.selected    = true;
            s.lastUpdated = new Date()
        })
    }


    // async function checkDbStatusTimestamp() {
    //     if (!state.currentCustomer) {
    //         return
    //     }
    //
    //     let oneHourAgo = moment().subtract(1, "hour").toISOString()
    //     const dbStatus = getDbStatus();
    //
    //     if (!dbStatus[state.currentCustomer?.id] || dbStatus[state.currentCustomer?.id]?.timestamp < oneHourAgo) {
    //         // noinspection JSIgnoredPromiseFromCall
    //         await refreshDbStatus()
    //     }
    // }

    // async function refreshDbStatus() {
    //     if (!state.currentCustomer) {
    //         return
    //     }
    //
    //     await fetchDbStatus()
    //
    //     while (!getDbStatus()[state.currentCustomer?.id] || getDbStatus()[state.currentCustomer?.id].status !== 'Online') {
    //         await waitForMS(5000);
    //         await fetchDbStatus();
    //     }
    // }

    // async function fetchDbStatus() {
    //     if (!state.currentCustomer) {
    //         return
    //     }
    //     let result                          = await doGet(DB_STATUS_URL, null, state.currentCustomer?.id) as any;
    //     let dbStatus                        = getDbStatus()
    //     dbStatus[state.currentCustomer?.id] = {...result.data, timestamp: moment().toISOString()}
    //     setLocalStorageItem("dbStatus", dbStatus, 86400)
    // }

    // function waitForMS(ms = 1000) {
    //     return new Promise(resolve => {
    //         setTimeout(resolve, ms);
    //     });
    // }

    // function updateDbStatusTimestamp() {
    //     if (!state.currentCustomer) {
    //         return
    //     }
    //
    //     const dbStatus  = getDbStatus();
    //     const statusObj = dbStatus[state.currentCustomer?.id];
    //     if (statusObj) {
    //         statusObj.timestamp = moment().toISOString()
    //         setLocalStorageItem("dbStatus", dbStatus, 86400)
    //     }
    // }

    async function fetchUiTools() {

        NAVIGATION   = getNavigation()
        DESCRIPTIONS = getDescriptions()
        DRILLDOWNS   = getDrilldowns()
        FORMS        = getForms()

        if (!NAVIGATION || !DRILLDOWNS || !DESCRIPTIONS || !FORMS) {

            let nav = await doGet(NAV_URL, null, state.currentCustomer?.id) as any

            setLocalStorageItem("nav", nav.data[0].categories, 86400)
            setLocalStorageItem("descriptions", nav.data[0].descriptions, 86400)
            setLocalStorageItem("drilldowns", nav.data[0].drilldowns, 86400)
            setLocalStorageItem("forms", nav.data[0].forms, 86400)

            NAVIGATION   = getNavigation()
            DESCRIPTIONS = getDescriptions()
            DRILLDOWNS   = getDrilldowns()
            FORMS        = getForms()

        }

    }

    async function fetchCustomers(customerName: string | null = null) {
        CUSTOMERS = getCustomers()

        if (!CUSTOMERS) {
            let groupResponse = await doGet(CUSTOMERS_URL, null, state.currentCustomer?.id) as any
            setLocalStorageItem("customers", groupResponse.data, 86400 * 30)
            CUSTOMERS = getCustomers()
        }

        if (CUSTOMERS.length > 0) {
            if (customerName !== null) {

                const foundCustomer = CUSTOMERS.find(c => c.name === customerName);
                if (foundCustomer) {
                    state.currentCustomer = foundCustomer
                } else {
                    throw new Error("Unknown customer")
                }
            } else if (getCurrentCustomer()) {
                state.currentCustomer = getCurrentCustomer()
            } else {
                state.currentCustomer = CUSTOMERS[0]
            }
        } else {
            state.currentCustomer = null
        }
        save()
    }

    async function fetchUIStates() {
        try {
            let toolStackStates = getToolStackStates()

            if (!toolStackStates) {
                let groupResponse = await doGet(TOOL_STATE_URL, null, state.currentCustomer?.id) as any
                let toolStateJson = groupResponse.data;
                toolStackStates   = []
                toolStateJson.forEach((t: {
                    ToolStackStateJson: string;
                }) => toolStackStates.push(JSON.parse(t.ToolStackStateJson)))
                setLocalStorageItem("toolStackStates", toolStackStates, 86400)
            }
        } catch (e) {
            console.log(e)
        }
    }

    async function saveStates(allCustomers: boolean = false) {

        return new Promise(async (resolve, reject) => {
            try {

                if (!state.toolStacks) {
                    console.log('skipping state check - not loaded');
                    resolve("Promise resolved successfully");
                    return;
                }

                if (refreshingStates) {
                    console.log('skipping state check - refresh in progress');
                    resolve("Promise resolved successfully");
                    return;
                }

                let currentlySavedStates = getToolStackStates()
                let newStackStates       = getNewToolStackStates()

                let dirtyStackStates = currentlySavedStates ? newStackStates.filter((stackState: StackState) => {
                    let currentState   = currentlySavedStates.find((s: StackState) => s.key === stackState.key)
                    const errorMessage = isStackStateEqual(stackState, currentState);
                    // if (errorMessage) {
                    //     console.log(errorMessage)
                    //     console.log(stackState)
                    //     console.log(currentState)
                    // }
                    return errorMessage !== null
                }) : []


                if (dirtyStackStates.length === 0) {
                    // console.log("No new states found")
                    setStateSaved(true)
                    resolve("Promise resolved successfully");
                    return;
                }

                refreshingStates = true

                console.log(`Refreshing ${dirtyStackStates.length} state(s)`)

                let response;


                if (allCustomers) {
                    for (const c of getCustomers()) {
                        try {
                            response = await doPost(TOOL_STATE_URL, dirtyStackStates, null, c.id) as any
                        } catch (e) {
                            console.log(e)
                        }
                    }
                }

                response = await doPost(TOOL_STATE_URL, dirtyStackStates, null, state.currentCustomer?.id) as any

                const responseData = response.data;
                const newStates    = [] as ToolState[]

                responseData.forEach((t: any) => newStates.push(JSON.parse(t.ToolStackStateJson)))

                setLocalStorageItem("toolStackStates", newStates, 86400)

                refreshingStates = false
                setStateSaved(true)

                console.log(`States refreshed`)
                resolve("Promise resolved successfully");

            } catch (e) {
                console.error(e)
                refreshingStates = false
                reject(e)
            }
        });
    }


    async function deleteToolStates() {
        state.clearingCache = 'Clearing app states...'
        save()
        let response = await doDelete(TOOL_STATE_URL, null, state.currentCustomer?.id) as any
        console.log(response)
        // @ts-ignore
        state.clearingCache = 'Clearing local cache...'
        save()
        clearCache()
        // @ts-ignore
        state.clearingCache = 'Reloading page...'
        save()
        setTimeout(() => {
            // @ts-ignore
            window.location.reload(true);
        }, 10)
    }

    function refreshTool(tool: Tool) {
        return new Promise(async (resolve, reject) => {

            if (!tool) {
                resolve(null)
                return
            }

            if (tool.isLoading) {
                resolve(`${tool.title} already loading`)
                return
            }

            try {

                tool              = getByUUID(tool.uuid) as Tool
                tool.silentReload = true
                save()

                await doLoadTool(tool)
                tool              = getByUUID(tool.uuid) as Tool
                tool.silentReload = false
                save()
                resolve(`${tool.title} reloaded`)
            } catch (e) {
                console.error(e);
                tool = getByUUID(tool.uuid) as Tool
                save()
                reject(e)
            }
        });
    }

    function closeMermaidDrilldown() {
        state.mermaidModalOpen = false
        state.mermaidModalData = null
        save()
    }

    async function loadMermaidModal(queueName: any) {
        state.mermaidModalData    = null
        state.drilldownHeaderText = null
        state.mermaidModalOpen    = true
        state.detailActions       = null
        save()


        state.detailActions = [
            {
                name: "delete queued messages", onClick: () => {
                    doPost(QUEUE_ACTIONS_URL, {
                        actionName: "delete queued messages",
                        queueName : queueName
                    }, null, state.currentCustomer?.id)
                }
            },
            {
                name: "delete dead letters", onClick: () => {
                    doPost(QUEUE_ACTIONS_URL, {
                        actionName: "delete dead letters",
                        queueName : queueName
                    }, null, state.currentCustomer?.id)
                }
            },
            {
                name: "resend dead letters", onClick: () => {
                    doPost(QUEUE_ACTIONS_URL, {
                        actionName: "resend dead letters",
                        queueName : queueName
                    }, null, state.currentCustomer?.id)
                }
            }
        ]

        let response = await doGet(`${QUEUE_LOCATIONS_URL}/${queueName}`, null, state.currentCustomer?.id) as any

        console.log(response)

        if (response.data[0].addQuery) {
            state.detailActions = [...state.detailActions, {
                name       : "insert messages", onClick: () => {
                    doPost(QUEUE_ACTIONS_URL, {
                        actionName: "insert messages",
                        queueName : queueName
                    }, null, state.currentCustomer?.id)
                },
                description: response.data[0].addQuery
            }]
        }

        state.mermaidModalData    = {
            title         : `Queue actions for '${queueName}'`,
            queuedMessages: response.data[0].queuedMessages,
            deadLetters   : response.data[0].deadLetters
        }
        state.drilldownHeaderText = `Queue actions for '${queueName}'`

        save()
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                state.initializing = true
                state.currentCustomer = getCurrentCustomer()
                save()
                await fetchCustomers();
                save()

                await fetchUiTools();
                await fetchUIStates();

                let toolStackStates = getToolStackStates()

                state.toolStacks = createToolStacks(NAVIGATION, toolStackStates, state.currentCustomer?.id)
                save()
                selectToolStack(state.toolStacks.find((ts: ToolStack) => ts.selected) || state.toolStacks[0])
                state.initializing = false
                save()
                const subscribeMethod = (topic: string, msg: any) => {
                    let tool         = getAnyByUUID(msg[0], state.toolStacks) as Tool
                    tool.customTitle = msg[1]
                    save()
                }

                PubSub.subscribe('tool_customTitle', subscribeMethod);


            } catch (e) {
                state.error     = true
                state.errorData = e
                save()
                console.error(e)
            }
        };

        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const MINUTE_MS = 5000;


    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                await saveStates();
            } catch (e) {
                console.error(e)
            }
        }, MINUTE_MS);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        const handleTabClose = async (event: any) => {
            event.preventDefault();
            event.returnValue = '';
            await saveStates()
        }

        window.addEventListener('beforeunload', handleTabClose);
        return () => window.removeEventListener('beforeunload', handleTabClose);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const muiTheme = getMuiTheme({sideBarWidth: state.sideBarWidth});

    function getToolChildren() {

        if (state.toolComponent) {
            return React.createElement(state.toolComponent.component, {
                key: state.toolComponent.categoryName + "_" + state.toolComponent.groupName
            });

        }

        if (!state.currentFrame) {
            return null;
        } else if (state.currentFrame.type !== "GROUP") {
            return <BaseToolComponent tool={state.currentFrame.toolOrGroup}/>;
        } else if ((state.currentFrame.toolOrGroup as ToolGroup).tabs?.length > 0) {
            return <BasicTabs groupOrTab={state.currentFrame.toolOrGroup as ToolGroup}/>
        } else if (
            (state.currentFrame.toolOrGroup as ToolGroup).parsedTools &&
            (state.currentFrame.toolOrGroup as ToolGroup).parsedTools.length === 1 &&
            (state.currentFrame.toolOrGroup as ToolGroup).parsedTools[0].toolType === 'PDF'
        ) {
            return (state.currentFrame.toolOrGroup as ToolGroup).parsedTools.map(tool => <BaseToolComponent
                key={tool.uuid} tool={tool}/>);
        } else {
            return <ToolGridComponent toolGroupOrToolTab={state.currentFrame.toolOrGroup}/>;
        }

    }


    const toolChildren: JSX.Element | JSX.Element[] | null = getToolChildren();

    return (

        <MyDataContext.Provider value={[state, setState]}>
            <ThemeProvider theme={muiTheme}>
                <CssBaseline/>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div style={{}}>
                        <Sidebar/>
                    </div>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        {!state.toolComponent &&
                            <ToolBreadcrumbs stackFrame={state.currentFrame} toolStack={state.currentStack}
                                             stateSaved={stateSaved}/>}
                        {state.error && <ErrorComponent errorResponse={state.errorData}/>}
                        {toolChildren}
                    </div>
                </div>
                {state.mermaidModalOpen ? <DrillDownModal/> : null}
                {state.currentCustomer && state.currentStack && formModalOpen &&
                    <FormComponent customerObjectID={state.currentCustomer.id}
                                   dbName={formModalDbName}
                                   idColumnName={formModalIdColumnName}
                                   open={formModalOpen}
                                   detailID={formModalEntityID}/>}
                {state.currentCustomer && getDbStatus()[state.currentCustomer.id] &&
                    <DbStatusModal dbStatus={getDbStatus()[state.currentCustomer.id]}/>}
                {state.initializing && !state.error && <LoadingModal>Loading App...</LoadingModal>}
                {state.clearingCache !== null && <LoadingModal>{state.clearingCache}</LoadingModal>}
            </ThemeProvider>
        </MyDataContext.Provider>
    );
}
