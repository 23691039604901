import {ToolCategory, ToolStack} from "../../classes";
import * as Icon from "react-feather";
import {Accordion, AccordionDetails, AccordionSummary, List, Theme} from "@mui/material";
import {
    sidebarBackgroundHoverColor,
    sidebarBgColor,
    sidebarSelectedBackgroundColor,
    sidebarSelectedBackgroundHoverColor,
    sidebarSelectedTextColor,
    sidebarSelectedTextHoverColor,
    sidebarTextHoverColor,
    sidebarTxColor,
    textFont,
    themeColor2,
    whiteColor
} from "../../styles/getMuiTheme";
import {SidebarToolGroup} from "./SidebarToolGroup";
import PropTypes from "prop-types";
import React from "react";
import {makeStyles} from "@mui/styles";
import {additionalTools} from "../../util/ToolUtils";

export function SidebarCategory({
                                    category,
                                    expanded,
                                    handleChange,
                                    sideBarWidth,
                                    toolStacks,
                                    handleSidebarToolClick,
                                    handleSidebarAdditionalToolClick
                                }: {
    category: ToolCategory,
    expanded: string | false,
    handleChange: any,
    sideBarWidth: number,
    toolStacks: ToolStack[],
    handleSidebarToolClick: any,
    handleSidebarAdditionalToolClick: any
}) {

    // @ts-ignore
    const IconTag = Icon[category.iconName ? category.iconName : 'HelpCircle'] as any || Icon['HelpCircle'];

    const categoryName = category.name;

    const useStyles = makeStyles(
        // @ts-ignore
        (theme: Theme) => {
            return ({
                categoryName    : {
                    marginLeft  : "12px",
                    textOverflow: "ellipsis",
                    whiteSpace  : "nowrap",
                    display     : "flex"
                },
                MuiAccordion    : {
                    backgroundColor: sidebarBgColor.hex() + " !important",
                    color          : sidebarTxColor.hex() + " !important",
                    boxShadow      : "0 0 0 0 !important",
                    "&:before"     : {
                        backgroundColor: "rgba(0,0,0,0) !important",
                    }
                },
                accordionSummary: {
                    backgroundColor : sidebarBgColor.hexa(),
                    color           : sidebarTxColor.hex(),
                    borderRadius    : "6px",
                    fontFamily      : textFont,
                    padding         : "0 12px !important",
                    fontWeight      : theme.typography.fontWeightBold,
                    fontSize        : "15px",
                    '& .selected'   : {
                        borderRadius   : "6px",
                        backgroundColor: themeColor2.fade(0.8).hexa() + " !important",
                        '&:hover'      : {
                            backgroundColor: themeColor2.fade(0.5).hexa() + " !important"
                        },
                    },
                    '&.Mui-expanded': {
                        borderRadius   : "6px",
                        fontWeight     : '700',
                        backgroundColor: sidebarBackgroundHoverColor.hexa() + " !important",
                        color          : sidebarTextHoverColor.hexa() + " !important"
                    },
                    '&:hover'       : {
                        borderRadius   : "6px",
                        backgroundColor: sidebarBackgroundHoverColor.hexa() + " !important",
                        color          : sidebarTextHoverColor.hexa() + " !important"
                    },

                },
                accordionDetails: {
                    backgroundColor  : sidebarBgColor.hexa(),
                    color            : sidebarTxColor.hexa(),
                    padding          : "0 !important",
                    '& .MuiList-root': {
                        padding                    : "0",
                        '& .MuiListItemButton-root': {
                            padding         : "8px 12px 8px 10px",
                            margin          : "4px 0px 4px 38px",
                            color           : sidebarTxColor.hexa(),
                            backgroundColor : sidebarBgColor.hexa(),
                            borderRadius    : "6px",
                            '&:hover'       : {
                                fontWeight     : "bold",
                                color          : sidebarTextHoverColor.hexa(),
                                backgroundColor: sidebarBackgroundHoverColor.hexa()
                            },
                            "&.Mui-selected": {
                                backgroundColor: sidebarSelectedBackgroundColor.hexa(),
                                color          : sidebarSelectedTextColor.hexa(),
                                fontWeight     : "bold",
                                "&:hover"      : {
                                    color          : sidebarSelectedTextHoverColor.hexa(),
                                    backgroundColor: sidebarSelectedBackgroundHoverColor.hexa(),
                                    fontWeight     : "800",
                                }
                            }
                        }
                    }
                }
            });
        }
    );

    const classes = useStyles()

    return <Accordion key={categoryName}
                      id={categoryName.replaceAll(" ", "_") + "_accordion"}
                      classes={{root: classes.MuiAccordion}}
                      expanded={expanded === categoryName}
                      onChange={handleChange(categoryName)}>
        <AccordionSummary key={categoryName}
                          expandIcon={<Icon.ChevronDown size={20}
                                                        color={whiteColor.fade(0.5).hexa()}/>}
                          classes={{root: classes.accordionSummary}}>
            <IconTag/>
            {sideBarWidth > 200 ?
                <div id={categoryName.replaceAll(" ", "_") + "_label"}
                     className={classes.categoryName}>{categoryName}</div> : null}
        </AccordionSummary>
        <AccordionDetails key={categoryName} className={classes.accordionDetails}>
            <List>
                {
                    toolStacks
                        .filter((toolStack: ToolStack) => toolStack.toolCategoryName === categoryName)
                        .map((toolStack: ToolStack, toolIndex: number) =>
                            <SidebarToolGroup classes={classes}
                                              handleSidebarToolClick={() => handleSidebarToolClick(toolStack)}
                                              toolIndex={toolIndex}
                                              componentKey={toolStack.key}
                                              selected={toolStack.selected}
                                              title={toolStack.title}
                                              toolCategoryName={toolStack.toolCategoryName}/>
                        )
                }
                {
                    additionalTools.filter((t: any) => t.categoryName === categoryName).map((t: any, i: number) => {
                        return <SidebarToolGroup classes={classes}
                                                 handleSidebarToolClick={() => handleSidebarAdditionalToolClick(t)}
                                                 toolIndex={i}
                                                 componentKey={t.categoryName + "_" + t.groupName}
                                                 selected={false}
                                                 title={t.groupName}
                                                 toolCategoryName={t.categoryName}/>

                    })
                }
            </List>
        </AccordionDetails>
    </Accordion>;
}

SidebarCategory.propTypes = {
    category                        : PropTypes.any.isRequired,
    expanded                        : PropTypes.any.isRequired,
    handleChange                    : PropTypes.func.isRequired,
    sideBarWidth                    : PropTypes.number.isRequired,
    toolStacks                      : PropTypes.any.isRequired,
    handleSidebarToolClick          : PropTypes.func.isRequired,
    handleSidebarAdditionalToolClick: PropTypes.func.isRequired

}