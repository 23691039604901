import React, {useEffect} from 'react';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup} from '@mui/material';
import {wrapperProps} from '@data-driven-forms/common/multiple-choice-list';

import {useFieldApi} from '@data-driven-forms/react-form-renderer';
import {FormFieldGrid, validationError} from "@data-driven-forms/mui-component-mapper";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import getColumnLabelString from "../../../util/TextUtils";
import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api";
import {KeyValueDisplay} from "../../freeform/KvLayout";

const PREFIX = 'Radio';

const classes = {
    grid: `${PREFIX}-grid`,
};

const StyledFormFieldGrid = styled(FormFieldGrid)(() => ({
    [`&.${classes.grid}`]: {
        '&:first-of-type': {
            marginTop: 8,
        },
        marginLeft: "10px",


    },
}));

const RadioOptionCustom = ({
                               name,
                               option,
                               hideLabel,
                               isDisabled,
                               isRequired,
                               isReadOnly,
                               FormControlLabelProps,
                               RadioProps: {inputProps, ...RadioProps}, ...props
                           }: any) => {
    const {input} = useFieldApi({name, type: 'radio', value: option});

    return (
        <FormControlLabel
            key={`${name}-${option.label}-${option.value}`}
            control={
                <Radio
                    {...input}
                    name={name}
                    value={option}
                    required={isRequired}
                    disabled={isDisabled || isReadOnly}
                    onChange={() => input.onChange(option)}
                    inputProps={{
                        readOnly: isReadOnly,
                        ...inputProps,
                    }}
                    {...RadioProps}
                />
            }
            label={hideLabel ? null : option.label !== option.value ? `${option.label} (${option.value})` : option.label}
            {...FormControlLabelProps}
        />
    );
};

RadioOptionCustom.propTypes = {
    name                 : PropTypes.string.isRequired,
    hideLabel            : PropTypes.bool,
    option               : PropTypes.shape({
        label: PropTypes.node.isRequired,
        value: PropTypes.any.isRequired
    }).isRequired,
    isReadOnly           : PropTypes.bool,
    isDisabled           : PropTypes.bool,
    isRequired           : PropTypes.bool,
    FormControlLabelProps: PropTypes.object,
    RadioProps           : PropTypes.object,
};

const RadioCustom = ({name, ...props}: any) => {
    const {
              options,
              orientation,
              isInTable,
              isDisabled,
              label,
              isRequired,
              helperText,
              description,
              isReadOnly,
              meta,
              validateOnMount,
              FormFieldGridProps,
              FormControlProps,
              FormLabelProps,
              FormHelperTextProps,
              ...rest
          } = useFieldApi({
        ...props,
        name,
        type: 'radio',
    });

    const {initialValues} = useFormApi();
    const invalid         = validationError(meta, validateOnMount);
    const text            = invalid || ((meta.touched || validateOnMount) && meta.warning) || helperText || description;
    const isRow = (orientation === 'HORIZONTAL')
//     console.log("should be true if horizontal",isRow)
    useEffect(() => {
        let currentFormValue = initialValues[name]
        if (typeof currentFormValue === "object") {
            let value = options.find((o: any) => o.value === currentFormValue.value && o.label === currentFormValue.label)
            rest.input.onChange(value)
        }
    }, []);

    return isInTable ?
        <TableRow>
            <TableCell style={{whiteSpace: "pre"}}><span
                style={{fontSize: "0.9285714285714286rem"}}>{getColumnLabelString(label, 70)}</span>{isRequired &&
                !meta.valid && <span style={{color: "#f00", fontSize: "0.75rem"}}> (Required)</span>}</TableCell>
            {options.map((option: any) => <TableCell key={`${name}-${option.label}-${option.value}`}>
                <FormControl required={isRequired} error={!!invalid} {...FormControlProps}>
                    <RadioOptionCustom name={name}
                                       option={option}
                                       hideLabel
                                       isDisabled={isDisabled}
                                       isRequired={isRequired}
                                       isReadOnly={isReadOnly} {...rest} />
                </FormControl>
            </TableCell>)}
        </TableRow>

        : <StyledFormFieldGrid className={classes.grid} {...FormFieldGridProps}>
            {isReadOnly ? <KeyValueDisplay continuation={'lines'}
                                           display={`${initialValues[name]?.label} (${initialValues[name]?.value})`}
                                           displayKey={label}
                                           index={0}
                                           margin={"0"}/> :
                <FormControl required={isRequired && !!invalid} error={!!invalid}
                             sx={{margin: "22px 0 5px 0"}}
                             component="fieldset" {...FormControlProps}>

                    <FormLabel component="legend" {...FormLabelProps}>
                        {label}{isRequired &&
                        !meta.valid && <span style={{color: "#f00", fontSize: "0.75rem"}}> (Required)</span>}
                    </FormLabel>

                    <RadioGroup row={isRow} style={{columnGap: '12px'}}>
                        {options.map((option: any) => <RadioOptionCustom key={`${name}-${option.label}-${option.value}`}
                                                                         name={name}
                                                                         option={option}
                                                                         isDisabled={isDisabled}
                                                                         isReadOnly={isReadOnly} {...rest} />)}
                        {text && <FormHelperText {...FormHelperTextProps}>{text}</FormHelperText>}
                    </RadioGroup>
                </FormControl>
            }
        </StyledFormFieldGrid>;
};

RadioCustom.propTypes = {
    ...wrapperProps,
    options              : PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.node,
        })
    ),
    label                : PropTypes.node.isRequired,
    isDisabled           : PropTypes.bool,
    description          : PropTypes.node,
    FormFieldGridProps   : PropTypes.object,
    FormControlProps     : PropTypes.object,
    FormControlLabelProps: PropTypes.object,
    RadioProps           : PropTypes.object,
    FormLabelProps       : PropTypes.object,
    FormHelperTextProps  : PropTypes.object,
};

RadioCustom.defaultProps = {
    options              : [],
    FormFieldGridProps   : {},
    FormControlProps     : {},
    FormControlLabelProps: {},
    RadioProps           : {},
    FormLabelProps       : {},
    FormHelperTextProps  : {},
};

export default RadioCustom;